import { MutationHookOptions, useMutation } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  SendPhoneValidationCodeMutation,
  SendPhoneValidationCodeMutationVariables,
} from '@customer-booking/__generated__/graphql';

export const SEND_PHONE_VALIDATION_CODE = gql(/* GraphQL */ `
  mutation SendPhoneValidationCode {
    sendPhoneValidationCode
  }
`);

export const useSendPhoneValidationCode = (
  options?: MutationHookOptions<
    SendPhoneValidationCodeMutation,
    SendPhoneValidationCodeMutationVariables
  >
) => {
  return useMutation(SEND_PHONE_VALIDATION_CODE, {
    ...options,
  });
};
