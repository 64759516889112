import * as React from 'react';
import * as Switch from '@radix-ui/react-switch';

interface ToggleSwitchProps
  extends Omit<Switch.SwitchProps, 'onChange' | 'onCheckedChange'> {
  onChange: (checked: boolean) => void;
}

export const ToggleSwitch = React.forwardRef<
  HTMLButtonElement,
  ToggleSwitchProps
>(function InnerToggleSwitch({ onChange, ...props }, ref) {
  return (
    <Switch.Root
      ref={ref}
      {...props}
      onCheckedChange={onChange}
      style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
      className={`w-10 h-4 rounded-full relative cursor-pointer transition-colors duration-300
          ${
            props.checked
              ? 'bg-primary hover:bg-primary-dark'
              : 'bg-gray-300 hover:bg-gray-400'
          }`}
    >
      <Switch.Thumb
        className={`block w-4 h-4 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out
            ${props.checked ? 'translate-x-6' : 'translate-x-0'}`}
      />
    </Switch.Root>
  );
});

export default ToggleSwitch;
