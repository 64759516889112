import * as React from 'react';
import { useDatePickerState } from '@react-stately/datepicker';
import { useDatePicker, AriaDatePickerProps } from '@react-aria/datepicker';
import { DateValue } from '@internationalized/date';

import { CalendarDaysIcon } from '@hermes/icons/simple';

import { DateField } from './DateField';
import { Calendar } from '../Calendar';
import { Popover } from '../Popover';
import { twMerge } from 'tailwind-merge';
import { inputClasses } from '../Input';

export interface DatePickerProps<T extends DateValue>
  extends AriaDatePickerProps<T> {
  months?: 1 | 2;
  className?: string;
  'aria-invalid'?: boolean;
}

export const DatePicker = function DatePickerInner<T extends DateValue>({
  months,
  className,
  id,
  'aria-invalid': ariaInvalid,
  ...props
}: DatePickerProps<T>) {
  const state = useDatePickerState({ ...props });
  const pickerRef = React.useRef<HTMLDivElement>(null);
  const datePicker = useDatePicker(
    { ...props, 'aria-labelledby': id },
    state,
    pickerRef
  );

  const { groupProps, fieldProps, dialogProps, calendarProps } = datePicker;
  const { onPress, ...buttonProps } = datePicker.buttonProps;

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    state.isOpen ? state.close() : state.open();
  };

  return (
    <Popover.Root open={state.isOpen}>
      <Popover.Anchor>
        <div
          className="inline-flex group"
          data-state={state.isOpen ? 'open' : 'closed'}
          data-invalid={ariaInvalid || state.validationState === 'invalid'}
        >
          <div
            {...groupProps}
            ref={pickerRef}
            className={twMerge(
              inputClasses,
              `
                  bg-white rounded-r-none p-1 group-focus-within:border-primary group-focus-within:group-hover:border-primary group-hover:border-gray-300 relative flex items-center
                  group-data-[state=open]:border-primary group-data-[state=open]:group-hover:border-primary
                  group-data-[invalid=true]:border-froly60 group-data-[invalid=true]:group-data-[state=open]:border-froly60 group-data-[invalid=true]:group-hover:border-froly60
                `,
              className
            )}
          >
            <DateField {...fieldProps} />
          </div>
          <button
            {...buttonProps}
            onClick={handleButtonClick}
            className={twMerge(
              inputClasses,
              `rounded-l-none bg-primary/5 border-l-0 group-focus-within:border-primary group-focus-within:group-hover:border-primary group-hover:border-gray-300
              hover:bg-primary/10 focus-ring-visible
              group-data-[state=open]:border-primary group-data-[state=open]:group-hover:border-primary
              group-data-[invalid=true]:border-froly60 group-data-[invalid=true]:group-data-[state=open]:border-froly60 group-data-[invalid=true]:group-hover:border-froly60`
            )}
          >
            <CalendarDaysIcon className="size-5 fill-primary group-focus-within:text-primary" />
          </button>
        </div>
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          align="start"
          className="p-4"
          onInteractOutside={state.close}
          {...dialogProps}
        >
          <Calendar {...calendarProps} months={months} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
