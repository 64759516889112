import * as React from 'react';
import { FetchCustomerProfileQuery } from '@customer-booking/__generated__/graphql';
import { createContext } from '@hermes/utils/context';

import { AuthStatus } from '../types';
import { useFetchCustomerProfileLazy } from '../api';
import { useTokens } from './TokenProviderContext';

interface AuthContext {
  customerProfile: FetchCustomerProfileQuery | undefined;
  status: AuthStatus;
}

const [useAuth, Provider] = createContext<AuthContext>();

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { refreshTokenProvider, accessTokenProvider } = useTokens();
  const [status, setStatus] = React.useState<AuthStatus>('idle');

  const shouldSkipProfile = !(
    refreshTokenProvider.getToken() || accessTokenProvider.getToken()
  );

  const [fetchProfile, { data: customerProfile }] =
    useFetchCustomerProfileLazy();

  React.useEffect(() => {
    if (status !== 'idle') {
      return;
    }

    if (shouldSkipProfile) {
      setStatus('unauthenticated');
      return;
    }

    setStatus('fetching');
    fetchProfile({
      onCompleted: () => {
        setStatus('authenticated');
      },
      onError: () => {
        setStatus('unauthenticated');
      },
    });
  }, [shouldSkipProfile, fetchProfile, status]);

  React.useEffect(() => {
    return refreshTokenProvider.subscribe((token) => {
      if (token && !customerProfile) {
        setStatus('fetching');
        fetchProfile({
          onCompleted: () => {
            setStatus('authenticated');
          },
          onError: () => {
            setStatus('unauthenticated');
          },
        });
      } else {
        setStatus('unauthenticated');
      }
    });
  }, [customerProfile, fetchProfile, refreshTokenProvider]);

  const value = React.useMemo(
    () => ({
      customerProfile,
      status,
    }),
    [customerProfile, status]
  );

  return <Provider value={value}>{children}</Provider>;
};

export { useAuth };
