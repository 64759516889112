import * as React from 'react';
import { SVGProps } from 'react';
const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    strokeWidth={3}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
);
export default SvgCheckIcon;
