import { DEFAULT_LOCALE } from '../constants';

export const locales = ['fr', 'en'];

export const parseLocale = (locale?: string): 'fr' | 'en' => {
  switch (locale) {
    case 'fr':
    case 'en':
      return locale;
    default:
      return DEFAULT_LOCALE;
  }
};
