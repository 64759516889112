import * as React from 'react';
import { FormattedMessage, IntlFormatters, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { twJoin } from 'tailwind-merge';
import { useRouter } from 'next/router';

import { useCaptcha } from '@hermes/utils/hooks';
import {
  Button,
  Checkbox,
  Field,
  Input,
  PasswordInput,
  PhoneNumber,
  PhoneNumberInput,
} from '@hermes/ui';
import { useSignUp } from '../../api';
import { useTokens } from '../../state';
import { useLocale } from '@customer-booking/features/l10n';

export interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: PhoneNumber;
  tosAgreement: boolean;
  communicationConsent: boolean;
  referralCode: string;
  appleIdentityToken: string;
  facebookAccessToken: string;
  googleIdentityToken: string;
  companyId: string;
}

const signUpSchema = (formatMessage: IntlFormatters['formatMessage']) =>
  z.object({
    firstName: z.string().nonempty({
      message: formatMessage({
        description: 'Signup first name error',
        defaultMessage: 'Veuillez entrer votre prénom',
      }),
    }),
    lastName: z.string().nonempty({
      message: formatMessage({
        description: 'Signup last name error',
        defaultMessage: 'Veuillez entrer votre nom',
      }),
    }),
    email: z.string().email({
      message: formatMessage({
        description: 'Signup email error',
        defaultMessage: 'Veuillez entrer une adresse email valide',
      }),
    }),
    password: z
      .string()
      .min(7, {
        message: formatMessage({
          description: 'Signup password too short error',
          defaultMessage: 'Le mot de passe doit contenir au moins 7 caractères',
        }),
      })
      .refine(
        (value) => {
          const hasNumber = /\d/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasUpperCase = /[A-Z]/.test(value);
          const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

          return (
            hasNumber && hasLowerCase && hasUpperCase && hasSpecialCharacter
          );
        },
        formatMessage({
          description: 'Signup password missing character error',
          defaultMessage:
            'Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial',
        })
      ),
    phoneNumber: z
      .object({
        countryCode: z.string(),
        number: z.string(),
        internationalNumber: z.string(),
        isValid: z.boolean().optional(),
      })
      .refine(
        (value) => {
          return value.number.length > 0;
        },
        {
          message: formatMessage({
            description: 'Signup phone number required error',
            defaultMessage: 'Veuillez entrer un numéro de téléphone',
          }),
        }
      )
      .refine(
        (value) => {
          return value.isValid === true;
        },
        {
          message: formatMessage({
            description: 'Signup phone number format error',
            defaultMessage: 'Veuillez entrer un numéro de téléphone valide',
          }),
        }
      ),
    tosAgreement: z.boolean().refine((value) => value, {
      message: formatMessage({
        description: 'Signup tos agreement error',
        defaultMessage: 'Veuillez accepter les conditions générales',
      }),
    }),
    communicationConsent: z.boolean().optional(),
    referralCode: z.string().optional(),
    appleIdentityToken: z.string().optional(),
    facebookAccessToken: z.string().optional(),
    googleIdentityToken: z.string().optional(),
  });

export const SignUpForm = ({
  defaultValues,
}: {
  defaultValues: SignUpFormValues;
}) => {
  const passwordId = React.useId();
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { localizePath } = useLocale();

  const { setRefreshToken, setAccessToken } = useTokens();
  const [signUp, { loading: signUpLoading }] = useSignUp();

  const methods = useForm<SignUpFormValues>({
    defaultValues,
    resolver: zodResolver(signUpSchema(formatMessage)),
  });

  const { execute } = useCaptcha(
    process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_SITE_KEY as string
  );

  const { handleSubmit, formState, register } = methods;

  const passwordError = Boolean(formState.errors.password);

  const handleError = (errors: string[]) => {
    errors.forEach((errorCode) => {
      switch (errorCode) {
        case 'EMAIL_ALREADY_USED':
          methods.setError('email', {
            type: 'manual',
            message: formatMessage({
              description: 'Signup email already exists error',
              defaultMessage: 'Cette adresse email est déjà utilisée',
            }),
          });
          break;
        case 'PHONE_NUMBER_ALREADY_USED':
          methods.setError('phoneNumber', {
            type: 'manual',
            message: formatMessage({
              description: 'Signup phone number already exists error',
              defaultMessage: 'Ce numéro de téléphone est déjà utilisé',
            }),
          });
          break;
      }
    });
  };

  const onSubmit = async (values: SignUpFormValues) => {
    const newToken = await execute({ action: 'signup' });

    const useIdentityProvider = Boolean(
      values.appleIdentityToken ||
        values.facebookAccessToken ||
        values.googleIdentityToken
    );

    signUp({
      variables: {
        companyId: defaultValues.companyId
          ? Number(defaultValues.companyId)
          : undefined,
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          phoneNumber: values.phoneNumber.internationalNumber,
          tosAgreement: values.tosAgreement,
          communicationConsent: values.communicationConsent,
          referralCode: values.referralCode,
          externalAuthentication: useIdentityProvider
            ? {
                apple: values.appleIdentityToken
                  ? { identityToken: values.appleIdentityToken }
                  : undefined,
                facebook: values.facebookAccessToken
                  ? { accessToken: values.facebookAccessToken }
                  : undefined,
                google: values.googleIdentityToken
                  ? {
                      idToken: values.googleIdentityToken,
                      clientType: 'Web',
                    }
                  : undefined,
              }
            : undefined,
        },
      },
      context: {
        headers: {
          'X-Recaptcha-Token': newToken,
        },
      },
      onError: (error) => {
        if (error.graphQLErrors.length > 0) {
          handleError(
            error.graphQLErrors[0]?.extensions?.errorCodes as string[]
          );
        }
      },
      onCompleted: (data) => {
        setRefreshToken(data.signUp.refreshToken);
        setAccessToken(data.signUp.customer.auth.token);
        router.push(localizePath('/sign-up/validate-account'));
      },
    });
  };

  return (
    <>
      <form className="mt-4 w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2">
          <div className="grow">
            <Controller
              name="firstName"
              control={methods.control}
              render={({ field: { name, ...field }, fieldState }) => (
                <Field.Root name={name} error={fieldState.error?.message}>
                  <Field.Label>
                    <FormattedMessage
                      description="First name field label"
                      defaultMessage="Prénom"
                    />
                  </Field.Label>
                  <Field.Control>
                    <Input
                      {...field}
                      autoComplete="given-name"
                      placeholder="Marcel"
                      className="w-full"
                      type="text"
                    />
                  </Field.Control>
                  <Field.ErrorMessage />
                </Field.Root>
              )}
            />
          </div>
          <div className="grow">
            <Controller
              name="lastName"
              control={methods.control}
              render={({ field: { name, ...field }, fieldState }) => (
                <Field.Root name={name} error={fieldState.error?.message}>
                  <Field.Label>
                    <FormattedMessage
                      description="Last name field label"
                      defaultMessage="Nom de famille"
                    />
                  </Field.Label>
                  <Field.Control>
                    <Input
                      {...field}
                      autoComplete="family-name"
                      placeholder="Pagnol"
                      className="w-full"
                      type="text"
                    />
                  </Field.Control>
                  <Field.ErrorMessage />
                </Field.Root>
              )}
            />
          </div>
        </div>
        <Controller
          name="email"
          control={methods.control}
          render={({ field: { name, ...field }, fieldState }) => (
            <Field.Root name={name} error={fieldState.error?.message}>
              <Field.Label className="mt-2">
                <FormattedMessage
                  description="Email field label"
                  defaultMessage="Email"
                />
              </Field.Label>
              <Field.Control>
                <Input
                  {...field}
                  autoComplete="email"
                  placeholder="pagnol@marcel.com"
                  className="w-full"
                  type="email"
                />
              </Field.Control>
              <Field.ErrorMessage />
            </Field.Root>
          )}
        />
        <Controller
          name="password"
          control={methods.control}
          render={({ field: { name, ...field }, fieldState }) => (
            <Field.Root name={name} error={fieldState.error?.message}>
              <Field.Label className="mt-2">
                <FormattedMessage
                  description="Password field label"
                  defaultMessage="Mot de passe"
                />
              </Field.Label>
              <Field.Control>
                <PasswordInput
                  className="w-full"
                  autoComplete="new-password"
                  aria-describedby={passwordId}
                  {...field}
                />
              </Field.Control>
              <p
                id={passwordId}
                className={twJoin(
                  'text-sm py-2 text-primary',
                  passwordError && 'text-red-500'
                )}
              >
                <FormattedMessage
                  description="Password field helper text"
                  defaultMessage="Votre mot de passe doit contenir au moins 7 caractères, une majuscule, une minuscule et un chiffre."
                />
              </p>
            </Field.Root>
          )}
        />
        <Controller
          name="phoneNumber"
          control={methods.control}
          render={({
            field: { name, onChange, onBlur, ...field },
            fieldState,
          }) => (
            <Field.Root name={name} error={fieldState.error?.message}>
              <Field.Label className="mt-2">
                <FormattedMessage
                  description="Phone number field label"
                  defaultMessage="Numéro de téléphone"
                />
              </Field.Label>
              <PhoneNumberInput.Root
                className="w-full"
                onChange={onChange}
                {...field}
              >
                <PhoneNumberInput.CountryCodes locale="fr" />
                <Field.Control>
                  <PhoneNumberInput.Input
                    className="w-full"
                    placeholder="06 12 34 56 78"
                    autoComplete="tel"
                    onBlur={onBlur}
                  />
                </Field.Control>
              </PhoneNumberInput.Root>
              <Field.ErrorMessage />
            </Field.Root>
          )}
        />
        <Controller
          name="tosAgreement"
          control={methods.control}
          render={({
            field: { name, onChange, value, ...field },
            fieldState,
          }) => (
            <Field.Root name={name} error={fieldState.error?.message}>
              <div className="flex items-center gap-2 mt-4">
                <Field.Control>
                  <Checkbox
                    {...field}
                    checked={value === true}
                    onCheckedChange={(value) => onChange(value === true)}
                  />
                </Field.Control>
                <Field.Label className="leading-5">
                  <FormattedMessage
                    description="TOS agreement checkbox label"
                    defaultMessage="J'accepte <link>les conditions générales d'utilisation</link>"
                    values={{
                      link: (chunks) => (
                        <a
                          href="https://www.marcel.cab/cgu/"
                          target="_blank"
                          className="underline text-primary font-bold"
                          rel="noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </Field.Label>
              </div>
              <Field.ErrorMessage className="mt-1" />
            </Field.Root>
          )}
        />
        <div className="flex items-center gap-2 mt-2">
          <Controller
            name="communicationConsent"
            control={methods.control}
            render={({
              field: { name, onChange, value, ...field },
              fieldState,
            }) => (
              <Field.Root name={name} error={fieldState.error?.message}>
                <Field.Control>
                  <Checkbox
                    {...field}
                    checked={value === true}
                    onCheckedChange={(value) => onChange(value === true)}
                  />
                </Field.Control>
                <Field.Label className="leading-5">
                  <FormattedMessage
                    description="Communication consent checkbox label"
                    defaultMessage="J'accepte de recevoir des communications de la part de Marcel"
                  />
                </Field.Label>
                <Field.ErrorMessage />
              </Field.Root>
            )}
          />
        </div>
        <input type="hidden" {...register('appleIdentityToken')} />
        <input type="hidden" {...register('googleIdentityToken')} />
        <input type="hidden" {...register('facebookAccessToken')} />
        <Button
          type="submit"
          className="mt-6 w-full"
          variant="accent"
          loading={signUpLoading}
        >
          <FormattedMessage
            description="Sign up button label"
            defaultMessage="S'inscrire"
          />
        </Button>
      </form>
    </>
  );
};
