import { MutationHookOptions, useMutation } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from '@customer-booking/__generated__/graphql';

export const CHANGE_PASSWORD = gql(/* GraphQL */ `
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`);

export const useChangePassword = (
  options?: MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) => {
  return useMutation(CHANGE_PASSWORD, {
    ...options,
  });
};
