import { XMarkIcon } from '@hermes/icons/simple';
import { createContext } from '@hermes/utils/context';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { forwardRef } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

const borderVariants = {
  positive: 'border border-b-2 border-robins-egg-blue',
  negative: 'border border-b-2 border-froly',
  warning: 'border border-b-2 border-wattle',
  info: 'border border-b-2 border-bluemine40',
} as const;

const fontVariants = {
  positive: 'text-persian-green',
  negative: 'text-froly60',
  warning: 'text-[#9b9e00]',
  info: 'text-primary',
} as const;

const fillVariants = {
  positive: 'fill-persian-green',
  negative: 'fill-froly60',
  warning: 'fill-[#9b9e00]',
  info: 'fill-primary',
} as const;

export interface ToastContext {
  variant: 'positive' | 'negative' | 'warning' | 'info';
}

const [useToast, ToastProvider] = createContext<ToastContext>();

export interface ToastProps {
  children: React.ReactNode;
  variant?: ToastContext['variant'];
  duration?: number;
  className?: string;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

const Root = forwardRef(
  (
    {
      children,
      variant = 'info',
      duration = 3000,
      open,
      className,
      onOpenChange,
    }: ToastProps,
    ref: React.ForwardedRef<HTMLLIElement>
  ) => {
    return (
      <ToastProvider value={{ variant }}>
        <ToastPrimitive.Root
          ref={ref}
          open={open}
          onOpenChange={onOpenChange}
          duration={duration}
          className={twJoin(
            'bg-white relative rounded-xl data-[state=open]:animate-slideIn data-[state=closed]:animate-swipeOut data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut',
            'text-gray-700 p-4 px-4',
            borderVariants[variant],
            className
          )}
        >
          {children}
        </ToastPrimitive.Root>
      </ToastProvider>
    );
  }
);

const Close = ({ className }: { className?: string }) => {
  const { variant } = useToast();

  return (
    <ToastPrimitive.Close className="absolute top-2 right-2">
      <XMarkIcon
        className={twJoin('size-4', fillVariants[variant], className)}
      />
    </ToastPrimitive.Close>
  );
};

const Title = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const { variant } = useToast();

  return (
    <ToastPrimitive.Title
      className={twJoin('text', fontVariants[variant], className)}
    >
      {children}
    </ToastPrimitive.Title>
  );
};

const Description = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <ToastPrimitive.Description
      className={twMerge('text-gray-500 text-sm', className)}
    >
      {children}
    </ToastPrimitive.Description>
  );
};

export const Toast = {
  Root: Root,
  Description: Description,
  Title: Title,
  Close: Close,
};
