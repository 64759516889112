import * as React from 'react';
import { SVGProps } from 'react';
const SvgChevronLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 19.5 8.25 12l7.5-7.5"
    />
  </svg>
);
export default SvgChevronLeftIcon;
