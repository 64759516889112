import * as React from 'react';
import { XMarkIcon } from '@hermes/icons/simple';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { twMerge } from 'tailwind-merge';

export type DialogRootProps = DialogPrimitive.DialogProps;

const Overlay = React.forwardRef(function InnerOverlay(
  { className, ...props }: DialogPrimitive.DialogOverlayProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <DialogPrimitive.DialogOverlay
      ref={ref}
      className={twMerge(
        'bg-black/20 data-[state=open]:animate-overlayShow fixed inset-0 z-10 overflow-y-auto grid center lg:p-12 place-items-center',
        className
      )}
      {...props}
    />
  );
});

const Content = ({
  className,
  ...props
}: DialogPrimitive.DialogContentProps) => (
  <DialogPrimitive.DialogContent
    onOpenAutoFocus={(e) => e.preventDefault()}
    onInteractOutside={(e) => e.preventDefault()}
    className={twMerge(
      'w-full h-full relative rounded-none bg-white p-6 focus:outline-none z-20',
      'data-[state=open]:animate-contentShow transition-all lg:max-h-[85vh] lg:h-auto lg:w-[90vw] lg:max-w-[450px] lg:rounded-lg bg-white lg:shadow-menu focus:outline-none',
      className
    )}
    {...props}
  />
);

const Title = ({ className, ...props }: DialogPrimitive.DialogTitleProps) => (
  <DialogPrimitive.DialogTitle
    className={twMerge(
      'leading-7 text-lg m-0 font-bold text-primary',
      className
    )}
    {...props}
  />
);

const Description = ({
  className,
  ...props
}: DialogPrimitive.DialogDescriptionProps) => (
  <DialogPrimitive.DialogDescription
    className={twMerge('text-primary mt-2 mb-5 text-base ', className)}
    {...props}
  />
);

const CloseButton = (props: DialogPrimitive.DialogCloseProps) => (
  <DialogPrimitive.Close {...props} tabIndex={1} asChild>
    <button
      tabIndex={0}
      className="text-primary hover:bg-primary/10 focus:shadow-primary/20 absolute top-[10px] right-[10px] inline-flex h-[35px] w-[35px] lg:h-[25px] lg:w-[25px] appearance-none items-center justify-center rounded-full focus:sm focus-ring-visible transition-colors duration-300"
      aria-label="Close"
    >
      <XMarkIcon className="size-6 lg:size-4 fill-primary stroke-primary" />
    </button>
  </DialogPrimitive.Close>
);

export const Dialog = {
  Content,
  Overlay,
  Title,
  Description,
  CloseButton,
  Root: DialogPrimitive.Root,
  Trigger: DialogPrimitive.Trigger,
  Portal: DialogPrimitive.Portal,
  Close: DialogPrimitive.Close,
};
