import { ApolloLink } from '@apollo/client';

import { GetNewTokenResult, getNewToken } from '../api/refreshToken';
import { AuthContextType } from './shared.types';
import { promiseToObservable } from './helpers';

export const generateTokenLink = new ApolloLink((operation, forward) => {
  const { refreshTokenProvider, cookieTokenProvider, accessTokenProvider } =
    operation.getContext() as AuthContextType;

  const refreshToken = refreshTokenProvider.getToken();

  if (accessTokenProvider.getToken() || !refreshToken) {
    return forward(operation);
  }

  return promiseToObservable(getNewToken(refreshToken)).flatMap(
    (response: unknown) => {
      const [token, error] = response as GetNewTokenResult;

      if (token) {
        accessTokenProvider.setToken(token);
        cookieTokenProvider.setToken(token);
      }

      if (error) {
        refreshTokenProvider.clearToken();
        cookieTokenProvider.clearToken();
      }

      return forward(operation);
    }
  );
});
