import { useLocale } from '@customer-booking/features/l10n';
import Script from 'next/script';
import * as React from 'react';

interface AppleClientConfig {
  clientId?: string | undefined;
  redirectURI?: string | undefined;
  responseMode?: string | undefined;
  scope?: string | undefined;
  state?: string | undefined;
  nonce?: string | undefined;
  usePopup?: boolean | undefined;
}

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (config: AppleClientConfig) => void;
      };
    };
  }
}

interface AppleSignInSuccessEvent extends Event {
  detail?: {
    user?: {
      name: {
        firstName: string;
        lastName: string;
      };
      email: string;
    };
    authorization: {
      code: string;
      id_token: string;
      state: string;
    };
  };
}

export type AppleSignInSuccessHandler = (
  appleIdentityToken: string,
  user?: {
    firstName: string;
    lastName: string;
    email: string;
  }
) => void;

const getLang = (locale: string) => {
  switch (locale) {
    case 'en':
      return 'en_US';
    case 'fr':
    default:
      return 'fr_FR';
  }
};

export interface AppleSignInProps {
  onSignInSuccess: AppleSignInSuccessHandler;
  type?: 'sign-in' | 'sign-up' | 'continue';
}

export const AppleSignIn = ({ onSignInSuccess, type }: AppleSignInProps) => {
  const id = React.useId();
  const { locale } = useLocale();

  const handleInitAppleID = () => {
    window.AppleID.auth.init({
      clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI,
      responseMode: 'web_message',
      nonce: id,
      usePopup: true,
    });
  };

  const handleSignInSuccess = (event: AppleSignInSuccessEvent) => {
    const appleIdentityToken = event.detail?.authorization.id_token;
    const user = event.detail?.user
      ? {
          firstName: event.detail.user.name.firstName,
          lastName: event.detail.user.name.lastName,
          email: event.detail.user.email,
        }
      : undefined;

    if (appleIdentityToken) onSignInSuccess(appleIdentityToken, user);
  };

  React.useEffect(() => {
    document.addEventListener('AppleIDSignInOnSuccess', handleSignInSuccess);

    return () => {
      document.removeEventListener(
        'AppleIDSignInOnSuccess',
        handleSignInSuccess
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center">
      <Script
        // use key to force mount-unmount on locale change
        key={locale}
        src={`https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${getLang(
          locale
        )}/appleid.auth.js`}
        onReady={handleInitAppleID}
      />
      <div
        id="appleid-signin"
        data-color="black"
        data-border="true"
        data-type={type}
        data-border-radius="50"
        className="h-[40px] w-full"
      />
    </div>
  );
};
