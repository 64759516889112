import * as React from 'react';
import { SVGProps } from 'react';
const SvgTrainIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinejoin="round"
      strokeWidth={3}
      d="M9 8.84c0-.933.438-1.807 1.271-2.225C12.246 5.625 16.613 4 24 4c7.388 0 11.754 1.625 13.728 2.615C38.563 7.033 39 7.907 39 8.839V36a2 2 0 0 1-2 2H11a2 2 0 0 1-2-2V8.84Z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M34 38v4M14 38v4"
    />
    <path d="M20.5 32a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM30.5 32a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M20 44h8"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M24 12v14"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M20 12h8M20 26h8"
    />
    <path strokeLinejoin="round" strokeWidth={3} d="M9 12h30v14H9z" />
  </svg>
);
export default SvgTrainIcon;
