import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { MagnifyingGlassIcon } from '@hermes/icons/simple';
import { Input, InputProps } from './Input';

export type SearchInputProps = InputProps;

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ role, className, ...props }, ref) => {
    return (
      <div
        className={twMerge(
          `group/search bg-white border border-gray-200 rounded-md outline-none focus:border-primary transition-border-color relative px-2 inline-flex flex-row items-center overflow-hidden shadow-sm focus-within:border-primary transition-border-color duration-300`,
          '[&:not(:focus-within)]:hover:border-gray-300',
          className
        )}
      >
        <MagnifyingGlassIcon
          aria-hidden="true"
          className="size-4 fill-gray-300 stroke-gray-300 group-focus-within/search:fill-primary group-focus-within/search:stroke-primary  transition-border-color duration-300"
          strokeWidth={1}
        />
        <Input
          ref={ref}
          type="search"
          autoComplete="off"
          className="w-full outline-none leading-8 px-3 pr-1 appearance-none shadow-none border-0 bg-white"
          {...props}
        />
      </div>
    );
  }
);
