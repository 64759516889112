import * as React from 'react';

export const useWatchPosition = (
  { enabled }: { enabled: boolean } = { enabled: false }
) => {
  const [position, setPosition] = React.useState<GeolocationPosition | null>(
    null
  );
  const [error, setError] = React.useState<GeolocationPositionError | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    let id = 0;
    if ('geolocation' in navigator) {
      id = navigator.geolocation.watchPosition(
        (position) => {
          setPosition(position);
          setLoading(false);
        },
        (error) => {
          setError(error);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      /* geolocation IS NOT available */
      console.log('geolocation IS NOT available');
    }

    return () => {
      if (id) navigator.geolocation.clearWatch(id);
    };
  }, [enabled]);

  return { position, error, loading };
};
