import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { createPortal } from 'react-dom';
import { mergeRefs } from 'react-merge-refs';
import Skeleton from 'react-loading-skeleton';

import { createContext } from '@hermes/utils/context';
import { useModal, useWatchMedia } from '@hermes/utils/hooks';

import { Input, InputProps } from '../Input';
import { PopoverContentProps, Popover as UIPopover } from '../Popover';
import { Dialog } from '../Dialog';

interface ComboboxState {
  displayDesktop: boolean;
  modalElement: HTMLElement | null;
  setModalElement: (el: HTMLElement | null) => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectionChange?: (value: any) => void;
  listId: string;
  isSelected: (value: unknown) => boolean;
  activeOptionId?: string;
  setActiveOptionId: (value: string) => void;
  nullable: boolean;
}

const [useCombobox, ComboboxProvider] = createContext<ComboboxState>();

const getList = (listId: string) => {
  try {
    return document.getElementById(listId);
  } catch {
    return undefined;
  }
};

const getOptions = (listId: string) => {
  return getList(listId)?.getElementsByTagName('li');
};

const useShortcuts = ({
  search,
  listId,
  currentIndex,
  onUpdateIndex,
  onEscape,
}: {
  search: string | undefined;
  listId: string;
  onUpdateIndex: (index: number) => void;
  currentIndex: number | null;
  onEscape?: () => void;
}) => {
  const { open, onOpenChange, selection } = useCombobox();

  const setNextOptionAsActive = () => {
    const options = getOptions(listId);
    if (!options) return;

    const index = currentIndex === null ? 0 : currentIndex + 1;
    const nextIndex = options?.item(index) ? index : 0;

    const nextOption = options?.item(nextIndex)?.id;

    if (nextOption) {
      onUpdateIndex(nextIndex);
    }
  };

  const setPreviousOptionAsActive = () => {
    const options = getOptions(listId);
    if (!options) return;

    const index = currentIndex === null ? options.length - 1 : currentIndex - 1;

    const previousIndex = options?.item(index) ? index : options.length - 1;
    const previousOption = options?.item(previousIndex)?.id;

    if (previousOption) {
      onUpdateIndex(previousIndex);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      !open &&
      e.key !== 'Enter' &&
      e.key !== 'Escape' &&
      e.key !== 'Tab' &&
      e.key !== 'Shift' &&
      e.key !== 'Ctrl' &&
      e.key !== 'Alt' &&
      e.key !== ' '
    ) {
      onOpenChange?.(true);
    }

    if (e.key === ' ' && search === '' && !selection) {
      e.preventDefault();
      e.stopPropagation();
      onOpenChange?.(true);
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onOpenChange?.(false);
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation();
      setNextOptionAsActive();
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      e.stopPropagation();
      setPreviousOptionAsActive();
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      onEscape?.();
    }

    if (e.key === 'Enter' && (!open || currentIndex === null)) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return {
    onKeyDown: handleKeyDown,
  };
};

const DesktopRoot = ({
  children,
  open,
  onOpenChange,
}: {
  children: React.ReactNode;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
}) => (
  <UIPopover.Root open={open} onOpenChange={onOpenChange}>
    {children}
  </UIPopover.Root>
);

const MobileRoot = (props: {
  children: React.ReactNode;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
}) => <Dialog.Root {...props} modal />;

export interface ComboboxRootProps<T> {
  children: React.ReactNode;
  selection?: T;
  onSelectionChange?: (value: T) => void;
  onOpenChange?: (open: boolean) => void;
  desktopBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | false;
  nullable?: boolean;
}

function ComboboxRoot<T>({
  children,
  selection: selectionProp,
  onSelectionChange: onSelectionChangeProp,
  onOpenChange,
  desktopBreakpoint = 'lg',
  nullable = false,
}: ComboboxRootProps<T>) {
  const popover = useModal({
    onOpenChange,
  });

  const [width, setWidth] = React.useState<number>();
  const matchMedia = useWatchMedia(desktopBreakpoint || 'lg');
  const [selection, setSelection] = React.useState<T | undefined>(
    selectionProp
  );
  const displayDesktop = desktopBreakpoint ? matchMedia : true;
  const [activeOptionId, setActiveOptionId] = React.useState<
    string | undefined
  >(undefined);
  const [modalElement, setModalElement] = React.useState<HTMLElement | null>(
    null
  );

  React.useEffect(() => {
    setSelection(selectionProp);
  }, [selectionProp]);

  const listId = React.useId();

  const handleSelectionChange = (value: T) => {
    setSelection(value);
    onSelectionChangeProp?.(value);
  };

  const value = {
    nullable,
    listId,
    width,
    displayDesktop,
    setWidth: (w: number) => setWidth(w),
    onSelectionChange: handleSelectionChange,
    modalElement,
    setModalElement,
    onOpenChange: popover.onOpenChange,
    open: popover.open,
    selection,
    activeOptionId,
    setActiveOptionId,
    isSelected: (value: unknown) => {
      if (
        value &&
        selection &&
        typeof value === 'object' &&
        typeof selection === 'object' &&
        'id' in value &&
        'id' in selection
      ) {
        return value.id === selection.id;
      }

      return value === selection;
    },
  };

  const RootProvider = displayDesktop ? DesktopRoot : MobileRoot;

  return (
    <ComboboxProvider value={value}>
      <RootProvider {...popover}>{children}</RootProvider>
    </ComboboxProvider>
  );
}

const ComboboxButton = React.forwardRef(function TriggerInner(
  {
    className,
    children,
    hideOnMobile,
  }: React.HTMLProps<HTMLButtonElement> & {
    hideOnMobile?: boolean;
  },
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const {
    displayDesktop,
    onOpenChange,
    setActiveOptionId,
    listId,
    modalElement,
    open,
  } = useCombobox();

  const innerRef = React.useRef<HTMLButtonElement>(null);

  const Trigger = displayDesktop ? DesktopTrigger : MobileTrigger;
  const [activeOptionIndex, setActiveOptionIndex] = React.useState<
    number | null
  >(null);

  const options =
    typeof document === 'undefined'
      ? undefined
      : document.getElementById(listId)?.getElementsByTagName('li');

  const shortcuts = useShortcuts({
    listId,
    search: undefined,
    currentIndex: activeOptionIndex,
    onUpdateIndex: (index: number) => {
      setActiveOptionIndex(index);
    },
  });

  React.useEffect(() => {
    if (!options || activeOptionIndex === null) return;

    const option = options?.item(activeOptionIndex);
    if (!option) return;

    setActiveOptionId(option.id);
  }, [activeOptionIndex, options, setActiveOptionId]);

  React.useEffect(() => {
    if (innerRef.current && modalElement) {
      innerRef.current.focus();
    }
  }, [modalElement]);

  return (
    <Trigger hideOnMobile={hideOnMobile}>
      <button
        ref={mergeRefs([innerRef, ref])}
        className={twMerge('focus-visible:outline-none', className)}
        onClick={() => onOpenChange(true)}
        data-state={modalElement ? 'open' : open ? 'open' : 'closed'}
        {...shortcuts}
      >
        {children}
      </button>
    </Trigger>
  );
});

const MobileTrigger = ({
  children,
  hideOnMobile = false,
}: {
  children: React.ReactNode;
  hideOnMobile?: boolean;
}) => {
  const { modalElement } = useCombobox();

  if (hideOnMobile) {
    return (
      <Dialog.Trigger className="w-full" asChild>
        {children}
      </Dialog.Trigger>
    );
  }

  const portalInput = modalElement
    ? createPortal(children, modalElement)
    : children;

  return modalElement ? (
    portalInput
  ) : (
    <Dialog.Trigger className="w-full" asChild>
      {children}
    </Dialog.Trigger>
  );
};

const DesktopTrigger = ({ children }: { children: React.ReactNode }) => {
  return <UIPopover.Trigger asChild>{children}</UIPopover.Trigger>;
};

export interface ComboboxInputProps<T> extends Omit<InputProps, 'as'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  displayValue?: (option: T) => string;
  as?: React.ElementType;
}

const ComboboxInput = React.forwardRef(function ComboboxInputInner<T>(
  {
    onChange,
    className,
    displayValue,
    as: As = Input,
    ...props
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ComboboxInputProps<T> & { [key: string]: any },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [search, setSearch] = React.useState('');
  const innerRef = React.useRef<HTMLInputElement>(null);
  const displayValueRef = React.useRef(displayValue);
  const [activeOptionIndex, setActiveOptionIndex] = React.useState<
    number | null
  >(null);

  const {
    onSelectionChange,
    displayDesktop,
    modalElement,
    selection,
    listId,
    open,
    setActiveOptionId,
    onOpenChange,
    nullable,
  } = useCombobox();

  const resetSearch = () => {
    if (selection) {
      setSearch(
        displayValueRef.current
          ? displayValueRef.current?.(selection)
          : selection
      );
    } else {
      setSearch('');
    }
  };

  const shortcuts = useShortcuts({
    search,
    listId,
    currentIndex: activeOptionIndex,
    onEscape: () => {
      resetSearch();
    },
    onUpdateIndex: (index: number) => {
      setActiveOptionIndex(index);
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    onOpenChange?.(true);
    onChange?.(e);

    if (nullable && e.currentTarget.value === '') {
      onSelectionChange?.('');
    }
  };

  const Trigger = displayDesktop ? DesktopTrigger : MobileTrigger;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    resetSearch();
    props?.onBlur?.(e);
  };

  React.useEffect(() => {
    if (innerRef.current && modalElement) {
      innerRef.current.focus();
    }
  }, [modalElement]);

  React.useEffect(() => {
    setSearch(
      displayValueRef.current ? displayValueRef.current?.(selection) : selection
    );
  }, [selection]);

  React.useEffect(() => {
    setActiveOptionIndex(null);
  }, [search]);

  React.useEffect(() => {
    const options = getOptions(listId);
    if (!options || activeOptionIndex === null) return;

    const option = options?.item(activeOptionIndex);
    if (!option) return;

    setActiveOptionId(option.id);
  }, [activeOptionIndex, listId, setActiveOptionId]);

  const content = (
    <As
      {...props}
      ref={mergeRefs([innerRef, ref])}
      onChange={handleChange}
      className={className}
      value={search}
      data-state={modalElement ? 'open' : open ? 'open' : 'closed'}
      onBlur={handleBlur}
      onFocus={(e: React.FocusEvent<HTMLInputElement, Element>) => {
        e.target.select();
      }}
      {...shortcuts}
    />
  );

  const portalContent = modalElement
    ? createPortal(content, modalElement)
    : content;

  return modalElement ? portalContent : <Trigger>{portalContent}</Trigger>;
});

export interface ComboboxOptionsProps {
  children: React.ReactNode;
  className?: string;
}

const ComboboxOptions = ({ children, className }: ComboboxOptionsProps) => {
  const { listId } = useCombobox();

  return (
    <ul id={listId} className={className}>
      {children}
    </ul>
  );
};

export interface ComboboxDesktopPopover
  extends Omit<PopoverContentProps, 'title'> {
  children: React.ReactNode;
}

const ComboboxDesktopPopover = ({
  children,
  ...props
}: ComboboxDesktopPopover) => {
  const { listId, open } = useCombobox();

  React.useEffect(() => {
    if (!open) return;
    setTimeout(() => {
      const options = getOptions(listId);
      if (!options) return;

      const selected = Array.from(options).find((option) => {
        const isSelected = option.getAttribute('aria-selected') === 'true';
        return isSelected;
      });

      if (selected) {
        selected.scrollIntoView({
          behavior: 'instant',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }, 0);
  }, [listId, open]);

  return (
    <UIPopover.Portal>
      <UIPopover.Content
        size="anchor"
        className="w-full h-full"
        onOpenAutoFocus={(e) => e.preventDefault()}
        {...props}
      >
        {children}
      </UIPopover.Content>
    </UIPopover.Portal>
  );
};

export interface ComboboxMobileDialogProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ComboboxMobileDialog = ({
  children,
  title,
  onMouseDown,
}: ComboboxMobileDialogProps) => {
  const { setModalElement } = useCombobox();

  return (
    <Dialog.Portal>
      <Dialog.Overlay>
        <Dialog.Content
          forceMount
          className="h-full w-full lg:h-full lg:w-full max-w-none max-h-none absolute overflow-y-auto lg:max-w-none lg:max-h-none"
          onMouseDown={onMouseDown}
        >
          {title && <Dialog.Title>{title}</Dialog.Title>}
          <Dialog.CloseButton />
          <div
            ref={(el: HTMLElement | null) => {
              setModalElement(el);
            }}
            className="my-2"
          />
          {children}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

const ComboboxPopover = ({
  children,
  title,
  ...props
}: ComboboxDesktopPopover & ComboboxMobileDialogProps) => {
  const { displayDesktop } = useCombobox();

  return displayDesktop ? (
    <ComboboxDesktopPopover {...props}>{children}</ComboboxDesktopPopover>
  ) : (
    <ComboboxMobileDialog {...props} title={title}>
      {children}
    </ComboboxMobileDialog>
  );
};

export interface ComboboxOptionProps<T> {
  children: React.ReactNode;
  value: T;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

function ComboboxOption<T>({
  children,
  value,
  className,
  onClick,
}: ComboboxOptionProps<T>) {
  const { onSelectionChange, activeOptionId, isSelected, onOpenChange } =
    useCombobox();

  const reactId = React.useId();
  const id = `combobox-option-${reactId}`;

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    onSelectionChange?.(value);
    onClick?.(e);
    onOpenChange(false);
  };

  React.useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && activeOptionId === id) {
        e.preventDefault();
        e.stopPropagation();
        onSelectionChange?.(value);
        onOpenChange(false);
      }
    };

    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  });

  return (
    <li
      id={id}
      role="option"
      aria-selected={isSelected(value)}
      data-comboboxui-state={[
        id === activeOptionId ? 'active' : false,
        isSelected(value) ? 'selected' : false,
      ]
        .filter(Boolean)
        .join(' ')}
      className={twMerge(
        'group/option px-4 py-2 flex gap-2 items-center text-biscay-10/80 cursor-pointer last-of-type:rounded-b-md first-of-type:rounded-t-md',
        'aria-selected:text-primary-dark aria-selected:bg-primary/10 hover:bg-gray-50 data-[comboboxui-state="selected"]:bg-primary/10 hover:aria-selected:bg-primary/10 data-[comboboxui-state="active selected"]:bg-primary/5 data-[comboboxui-state="active"]:bg-gray-50',
        className
      )}
      onClick={handleClick}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </li>
  );
}

export const ComboxOptionSkeleton = React.memo(
  ({ count }: { count: number }) => {
    return (
      <>
        {Array.from({ length: count }).map((_, index) => {
          const widthPercentage = Math.floor(Math.random() * 40) + 60;
          return (
            <div key={index} className="px-4 py-2 max-w-[220px]">
              <Skeleton height={30} width={`${widthPercentage}%`} />
            </div>
          );
        })}
      </>
    );
  }
);

export const Combobox = {
  Root: ComboboxRoot,
  Input: ComboboxInput,
  Button: ComboboxButton,
  Options: ComboboxOptions,
  Option: ComboboxOption,
  Popover: ComboboxPopover,
  OptionSkeleton: ComboxOptionSkeleton,
};
