import * as React from 'react';

export function usePrevious<T>(
  value: T,
  options: { enabled?: boolean } = { enabled: true }
): T | undefined {
  const [previous, setPrevious] = React.useState<T | undefined>(undefined);

  React.useEffect(() => {
    if (options.enabled) setPrevious(value);
  }, [value, options.enabled]);

  return previous;
}
