import * as React from 'react';
import { Layer } from 'react-map-gl';

export interface PathLayerProps {
  id: string;
  sourceId: string;
  dashed?: boolean;
}

export const PathLayer = ({ id, sourceId, dashed = false }: PathLayerProps) => {
  return (
    <Layer
      id={id}
      type="line"
      source={sourceId}
      layout={{
        'line-join': 'round',
        'line-cap': 'round',
      }}
      paint={{
        'line-color': '#1C5E7C',
        'line-width': 2,
        'line-dasharray': dashed ? [1, 2] : undefined,
      }}
    />
  );
};
