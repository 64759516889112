import * as React from 'react';
import { useCalendarCell, AriaCalendarCellProps } from '@react-aria/calendar';
import { isSameMonth, DateValue } from '@internationalized/date';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';

interface CalendarCellProps extends AriaCalendarCellProps {
  state: CalendarState | RangeCalendarState;
  currentMonth: DateValue;
}

export function CalendarCell({ state, date, currentMonth }: CalendarCellProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  const { cellProps, buttonProps, formattedDate } = useCalendarCell(
    { date },
    state,
    ref
  );

  return (
    <td {...cellProps} className="py-0.5 relative group/cell">
      <div
        {...buttonProps}
        aria-disabled={isOutsideMonth || buttonProps['aria-disabled']}
        ref={ref}
        className="group/button w-10 h-10 outline-none rounded-full focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black aria-disabled:pointer-events-none"
        tabIndex={0}
      >
        <div
          className={`
            w-full h-full flex items-center justify-center rounded-full transition-background duration-200
            hover:bg-primary/20 text-primary
            group-aria-selected/cell:bg-primary group-aria-selected/cell:text-white
            group-aria-disabled/button:text-gray-300
          `}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
