import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as zod from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';

import { MarceLogo } from '@customer-booking/components/MarcelLogo';
import {
  Button,
  Field,
  Input,
  PasswordInput,
  Separator,
  useToasts,
} from '@hermes/ui';
import { usePreviousRoute } from '@customer-booking/state';
import { useLocale } from '@customer-booking/features/l10n';

import {
  isUnauthorizedError,
  useAppleSignIn,
  useGoogleSignIn,
  useSignIn,
} from '../api';
import { Link } from '@customer-booking/components/Link';
import { AppleSignIn, AppleSignInSuccessHandler } from './AppleSignIn';
import { useTokens } from '../state';
import { FacebookSignIn, FacebookSignInSuccessHandler } from './FacebookSignIn';
import { useFacebookSignIn } from '../api/facebookSignIn';
import { GoogleSignIn, GoogleSignInSuccessHandler } from './GoogleSignIn';

const signInSchema = zod.object({
  email: zod
    .string()
    .min(1, { message: 'Please enter a valid email address' })
    .email({ message: 'Invalid email address' }),
  password: zod.string().min(1, { message: 'Please enter your password' }),
});

type FormValues = zod.infer<typeof signInSchema>;

export const SignInForm = () => {
  const { localizePath } = useLocale();
  const { formatMessage } = useIntl();
  const { setAccessToken, setRefreshToken } = useTokens();
  const { addToast } = useToasts();
  const router = useRouter();
  const { previousRoute } = usePreviousRoute();

  const serverErrorId = React.useId();
  const isPreviousRouteAllowed = !(
    previousRoute.includes('sign-in') ||
    previousRoute.includes('sign-up') ||
    previousRoute.includes('password')
  );

  const [signIn, { loading, error: serverError }] = useSignIn({
    onCompleted: () => {
      // check if previous is current path
      router.replace(
        isPreviousRouteAllowed ? previousRoute : localizePath('/')
      );
    },
  });

  const [appleSignIn, { loading: appleLoading }] = useAppleSignIn();
  const [facebookSignIn, { loading: facebookLoading }] = useFacebookSignIn();
  const [googleSignIn, { loading: googleLoading }] = useGoogleSignIn();

  const { handleSubmit, control } = useForm<FormValues>({
    resolver: zodResolver(signInSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSignInComplete = ({
    refreshToken,
    customer,
  }: {
    refreshToken: string;
    customer: {
      auth: {
        token: string;
      };
    };
  }) => {
    setRefreshToken(refreshToken);
    setAccessToken(customer.auth.token);
    router.replace(isPreviousRouteAllowed ? previousRoute : localizePath('/'));
  };

  const onSignInError = (
    token: {
      appleIdentityToken?: string;
      googleIdentityToken?: string;
      facebookAccessToken?: string;
    },
    user: {
      firstName: string;
      lastName: string;
      email: string;
    }
  ) => {
    router.push(
      `/sign-up/form?${new URLSearchParams({
        ...token,
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email ?? '',
      }).toString()}`
    );
  };

  const handleSignInWithAppleSuccess: AppleSignInSuccessHandler = (
    appleIdentityToken,
    user
  ) => {
    appleSignIn({
      variables: {
        appleIdentityToken: appleIdentityToken,
      },
      onCompleted: (data) => {
        onSignInComplete(data.appleLogin);
      },
      onError: (error) => {
        if (isUnauthorizedError(error) && user) {
          onSignInError({ appleIdentityToken }, user);
        } else {
          addToast({
            variant: 'negative',
            title: formatMessage({
              description: 'Apple sign-in error title',
              defaultMessage: 'Erreur lors de la connexion avec Apple',
            }),
            description: formatMessage({
              description: 'Apple sign-in error description',
              defaultMessage:
                'Une erreur est survenue lors de la connexion avec Apple. Veuillez réessayer.',
            }),
          });
        }
      },
    });
  };

  const handleSignInWithFacebookSuccess: FacebookSignInSuccessHandler = (
    facebookAccessToken,
    user
  ) => {
    facebookSignIn({
      variables: {
        accessToken: facebookAccessToken,
      },
      onCompleted: (data) => {
        onSignInComplete(data.facebookLogin);
      },
      onError: (error) => {
        if (isUnauthorizedError(error)) {
          onSignInError({ facebookAccessToken }, user);
        }
      },
    });
  };

  const handleSignInWithGoogleSuccess: GoogleSignInSuccessHandler = (
    idToken,
    user
  ) => {
    googleSignIn({
      variables: {
        idToken,
        clientType: 'Web',
      },
      onCompleted: (data) => {
        onSignInComplete(data.googleLogin);
      },
      onError: (error) => {
        if (isUnauthorizedError(error)) {
          onSignInError({ googleIdentityToken: idToken }, user);
        }
      },
    });
  };

  const handleSignIn = async (values: FormValues) => {
    signIn({
      variables: {
        credentials: {
          email: values.email,
          password: values.password,
        },
      },
    });
  };

  const title = formatMessage({
    description: 'Sign-in page title',
    defaultMessage: 'Heureux de vous revoir ! Identifiez-vous :',
  });

  return (
    <div className="max-w-[375px]">
      <Link
        href={localizePath('/')}
        className="m-auto block w-fit mb-4"
        aria-label={formatMessage({
          description: 'Home link',
          defaultMessage: 'Home',
        })}
      >
        <MarceLogo width="200" height="80" priority />
      </Link>
      <h2 className="text-3xl mb-6 text-primary text-center">{title}</h2>
      <div className="flex flex-col gap-2">
        <AppleSignIn
          onSignInSuccess={handleSignInWithAppleSuccess}
          type="continue"
        />
        <FacebookSignIn
          onSignInSuccess={handleSignInWithFacebookSuccess}
          type="continue"
        />
        <GoogleSignIn
          onSignInSuccess={handleSignInWithGoogleSuccess}
          type="continue"
        />
      </div>
      <div className="flex items-center my-4">
        <div className="flex-1 h-[1px] bg-gray-300" />
        <div className="mx-4 text-gray-500">
          <FormattedMessage description="Or separator" defaultMessage="Ou" />
        </div>
        <div className="flex-1 h-[1px] bg-gray-300" />
      </div>
      <form className="w-full" onSubmit={handleSubmit(handleSignIn)} noValidate>
        <Controller
          name="email"
          control={control}
          render={({ field: { name, ...field }, fieldState }) => (
            <Field.Root name={name} error={fieldState.error?.message}>
              <Field.Label>
                <FormattedMessage
                  description="Email field label"
                  defaultMessage="Email"
                />
              </Field.Label>
              <Field.Control>
                <Input
                  {...field}
                  className="w-full"
                  type="email"
                  placeholder={formatMessage({
                    description: 'Email field placeholder',
                    defaultMessage: 'duchamp@marcel.cab',
                  })}
                  aria-invalid={Boolean(serverError)}
                  autoComplete="email"
                />
              </Field.Control>
              <Field.ErrorMessage />
            </Field.Root>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { name, ...field }, fieldState }) => (
            <Field.Root name={name} error={fieldState.error?.message}>
              <Field.Label>
                <FormattedMessage
                  description="Password field label"
                  defaultMessage="Mot de passe"
                />
              </Field.Label>
              <Field.Control>
                <PasswordInput
                  {...field}
                  className="w-full"
                  placeholder="********"
                  aria-invalid={Boolean(serverError)}
                  autoComplete="current-password"
                />
              </Field.Control>
              <Field.ErrorMessage />
            </Field.Root>
          )}
        />
        {serverError && (
          <div id={serverErrorId} className={'text-froly60 leading-8'}>
            {isUnauthorizedError(serverError) ? (
              <FormattedMessage
                description="Invalid credentials error message"
                defaultMessage="Identifiant et/ou mot de passe invalide. Veuillez vérifier vos identifiants."
              />
            ) : (
              <FormattedMessage
                description="Server error message"
                defaultMessage="Une erreur inattendue est survenue. Veuillez réessayer."
              />
            )}
          </div>
        )}
        <Button
          variant="accent"
          className="w-full mt-4 leading-8"
          type="submit"
          loading={loading || appleLoading || facebookLoading || googleLoading}
        >
          <FormattedMessage
            description="Sign in button"
            defaultMessage="Connexion"
          />
        </Button>
      </form>
      <p className="mt-8 text-center text-primary">
        <Link href={localizePath('/reset-password')} variant="accent">
          <FormattedMessage
            description="Password forgotten link"
            defaultMessage="Mot de passe oublié ?"
          />
        </Link>
      </p>
      <Separator>
        <FormattedMessage description="Or separator" defaultMessage="Ou" />
      </Separator>
      <p className="text-center text-primary">
        <FormattedMessage
          description="Sign up link"
          defaultMessage="Vous n'avez pas de compte ?"
        />
        <br />
        <Link href={localizePath('/sign-up')} variant="accent">
          <FormattedMessage
            description="Sign up link"
            defaultMessage="Inscrivez-vous en cliquant ici"
          />
        </Link>
      </p>
    </div>
  );
};
