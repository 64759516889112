import { twJoin } from 'tailwind-merge';

interface SeparatorProps {
  children?: React.ReactNode;
  className?: string;
}

export const Separator = ({ className, children }: SeparatorProps) => {
  return (
    <div
      role="separator"
      className={twJoin('flex items-center m-4', className)}
    >
      <div className="flex-1 h-[1px] bg-gray-300" />
      {children ? (
        <>
          <div className="mx-4 text-gray-500">{children}</div>
          <div className="flex-1 h-[1px] bg-gray-300" />
        </>
      ) : null}
    </div>
  );
};
