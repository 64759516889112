import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  RefreshTokenQuery,
  RefreshTokenQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { useTokens } from '../state';
import { print } from 'graphql';

export const REFRESH_TOKEN = gql(/* GraphQL */ `
  query RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      auth {
        token
      }
    }
  }
`);

export const useGetNewToken = (
  options?: QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>
) => {
  const { setAccessToken } = useTokens();
  return useLazyQuery(REFRESH_TOKEN, {
    ...options,
    onCompleted: (data) => {
      setAccessToken(data.refreshToken.auth.token);
      options?.onCompleted?.(data);
    },
  });
};

export type GetNewTokenResult =
  | readonly [token: undefined, error: Error]
  | readonly [token: string, error: undefined];

export const getNewToken = async (
  refreshToken: string
): Promise<GetNewTokenResult> => {
  const response = await fetch(process.env.NEXT_PUBLIC_GRAPHQL_API as string, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: print(REFRESH_TOKEN),
      variables: {
        refreshToken,
      },
    }),
  });

  if (!response.ok)
    return [undefined, new Error('Failed to refresh token')] as const;

  const { data, errors } = await response.json();

  if (errors) {
    return [undefined, new Error('Failed to refresh token')] as const;
  }

  return [data.refreshToken.auth.token as string, undefined] as const;
};
