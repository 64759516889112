import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  FetchRideDirectionsQuery,
  FetchRideDirectionsQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { gql } from '@customer-booking/__generated__';

export const FETCH_RIDE_DIRECTIONS = gql(/* GraphQL */ `
  query FetchRideDirections(
    $destination: String!
    $origin: String!
    $waypoints: [String!]
  ) {
    place {
      directions(
        destination: $destination
        origin: $origin
        waypoints: $waypoints
      ) {
        type
        properties {
          distance
          duration
          bounds {
            northeast
            southwest
          }
        }
        features {
          geometry {
            coordinates
          }
          properties {
            distance
            duration
            bounds {
              northeast
              southwest
            }
          }
        }
      }
    }
  }
`);

export const useFetchRideDirections = (
  variables: FetchRideDirectionsQueryVariables,
  options?: Omit<
    QueryHookOptions<
      FetchRideDirectionsQuery,
      FetchRideDirectionsQueryVariables
    >,
    'variables'
  >
) => {
  return useQuery(FETCH_RIDE_DIRECTIONS, {
    ...options,
    variables,
  });
};
