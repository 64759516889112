import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { gql } from '@customer-booking/__generated__';
import {
  SignInQuery,
  SignInQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { useTokens } from '../state';

export const SIGN_IN = gql(/* GraphQL */ `
  query SignIn($credentials: LoginInput!) {
    login(credentials: $credentials) {
      customer {
        id @client
        auth {
          firstName
          lastName
          id
          token
          issuedAt
          expiresAt
        }
      }
      refreshToken
    }
  }
`);

export const useSignIn = (
  options?: QueryHookOptions<SignInQuery, SignInQueryVariables>
) => {
  const { setAccessToken, setRefreshToken } = useTokens();

  return useLazyQuery(SIGN_IN, {
    ...options,
    onCompleted: (data) => {
      setAccessToken(data.login.customer.auth.token);
      setRefreshToken(data.login.refreshToken);
      options?.onCompleted?.(data);
    },
  });
};

export const isUnauthorizedError = (error: ApolloError) => {
  return error.graphQLErrors[0]?.extensions.code === 'UNAUTHORIZED';
};
