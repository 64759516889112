import * as React from 'react';
import { SVGProps } from 'react';
const SvgPrestigeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prestige-icon_svg__Calque_2"
    data-name="Calque 2"
    viewBox="0 0 40.71 18.49"
    {...props}
  >
    <defs>
      <style>
        {
          '.prestige-icon_svg__cls-1,.prestige-icon_svg__cls-2{fill:#b78e40;stroke:#b78e40;stroke-width:.25px;stroke-linecap:round;stroke-linejoin:round}.prestige-icon_svg__cls-2{fill:none;stroke:#0a0d0e;stroke-width:.39px}'
        }
      </style>
    </defs>
    <g id="prestige-icon_svg__Calque_1-2" data-name="Calque 1">
      <path
        d="M40.11 16.26c-.04-.06-.14-1.15-.18-1.57-.05-.52-.12-1.03-.18-1.54-.01-.1-.02-.17.06-.24.08-.07.12-.15.09-.26-.07-.22-.36-.38-.54-.5-.23-.15-1.23-.6-1.49-.71-1.74-.78-6.12-1.3-7.76-1.4-.15 0-.65-.02-.75-.08-.25-.14-.51-.29-.75-.45-1.23-.78-3.96-1.98-4.49-2.21-.72-.31-2.02-.72-3.28-.89-1.23-.16-3.9-.24-5.23-.13-2.56.21-4.61.48-6.8 1.2-.25.08-2.56.91-3.09 1.14-.51.22-1.03.42-1.57.56-.56.14-1.14.22-1.71.25-.29.02-1.13.02-1.27.02-.24 0-.13.23-.1.4.02.13.03.26.02.4 0 .14-.03.27 0 .41.02.12.31.58.37.7.05.1 0 .21 0 .31-.05.27-.08.54-.15.8-.06.24-.55.7-.68.81-.05.04-.11.08-.11.16.02.66.13 1.85.17 2.25.03.26.3.59.47.66.25.11.79.24 1.18.32.74.16 1.48.28 2.23.36.12.01.39.05.46-.04.04-.06.04-.24.04-.32.03-.33.06-1.07.44-1.94.19-.44 1.04-1.78 2.73-1.83 1.56-.04 2.26.63 2.7 1.21.45.59.77 1.26.69 2.98v.13h17.19c-.03-.61-.02-1.5.15-2.09.5-1.65 1.93-2.51 3.36-2.43 1.06.06 1.91.61 2.4 1.33.62.9.71 2.02.39 3.24-.02.07.02.12.11.12 1.11-.04 4.14-.2 4.58-.36.18-.07.38-.3.42-.46.03-.13-.03-.23-.09-.32ZM10.25 9.45c-.35-.1-1.36-.54-1.54-.66-.14-.1-.28-.22-.1-.3 1.15-.47 2.5-.98 3.82-1.24 1.34-.26 2.7-.38 4.06-.45.32-.02.63-.03.95-.04l.85 3.31c-2.67-.1-5.25-.21-5.95-.29-.7-.08-1.42-.14-2.1-.34Zm18.07.7c-.07.05-.35.07-.47.08-.17.02-1.91.12-2.61.09-.16 0-3.13-.11-6.36-.23l-.85-3.33c.97-.02 1.65 0 2.62.14 1.3.18 2.77.68 3.7 1.06 1.25.51 2.46 1.12 3.63 1.79.09.05.5.3.34.4Z"
        style={{
          fill: '#fff',
          stroke: '#0a0d0f',
          strokeMiterlimit: 10,
        }}
      />
      <circle
        cx={8.49}
        cy={15.77}
        r={2.52}
        className="prestige-icon_svg__cls-2"
      />
      <circle
        cx={8.49}
        cy={15.77}
        r={1.06}
        className="prestige-icon_svg__cls-2"
      />
      <circle
        cx={32.22}
        cy={15.77}
        r={2.52}
        className="prestige-icon_svg__cls-2"
      />
      <circle
        cx={32.22}
        cy={15.77}
        r={1.06}
        className="prestige-icon_svg__cls-2"
      />
      <path
        d="M27.56 2.01c1.06 0 1.89-.88 1.89-1.89 0 1.09.72 1.89 1.89 1.89-1.17 0-1.83.82-1.83 1.83 0-1.01-.89-1.83-1.95-1.83ZM30.9 4.59c1.95 0 3.31-1.53 3.31-3.39 0 2 1.16 3.39 3.32 3.39-2.16 0-3.32 1.62-3.32 3.48 0-1.86-1.36-3.48-3.31-3.48Z"
        className="prestige-icon_svg__cls-1"
      />
    </g>
  </svg>
);
export default SvgPrestigeIcon;
