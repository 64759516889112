import { useCalendarGrid, AriaCalendarGridProps } from '@react-aria/calendar';
import { CalendarState, RangeCalendarState } from '@react-stately/calendar';
import {
  getWeeksInMonth,
  DateDuration,
  endOfMonth,
} from '@internationalized/date';
import { CalendarCell } from './CalendarCell';

interface CalendarGridProps extends AriaCalendarGridProps {
  state: CalendarState | RangeCalendarState;
  locale: string;
  offset?: DateDuration;
}

export function CalendarGrid({
  state,
  locale,
  offset = {},
  ...props
}: CalendarGridProps) {
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);

  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      ...props,
      startDate,
      endDate,
    },
    state
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <div className="inline-block rounded-md">
      <table {...gridProps}>
        <thead {...headerProps}>
          <tr>
            {weekDays.map((day, index) => (
              <th className="p-3 text-primary" key={index}>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
            <tr key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex, startDate)
                .map((date, i) =>
                  date ? (
                    <CalendarCell
                      key={i}
                      state={state}
                      date={date}
                      currentMonth={startDate}
                    />
                  ) : (
                    <td key={i} />
                  )
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
