import { TokenProvider, TokenProviderListener } from './TokenProvider.abstract';

class LocalStorageTokenProvider implements TokenProvider {
  private key: string;
  private token: string | undefined;
  private listeners = new Set<TokenProviderListener>();

  constructor(key: string) {
    this.key = key;

    try {
      this.token = localStorage.getItem(key) ?? undefined;
    } catch (error) {
      // gracefull error handling
      return;
    }
  }

  public getToken(): string | undefined {
    return this.token;
  }

  public setToken(token: string): void {
    if (this.token === token) return;

    this.token = token;
    this.listeners.forEach((listener) => listener(token));

    try {
      localStorage.setItem(this.key, token);
    } catch (error) {
      // gracefull error handling
      return;
    }
  }

  public clearToken(): void {
    this.token = undefined;

    try {
      localStorage.removeItem(this.key);
    } catch (error) {
      console.error(error);
      // gracefull error handling
      return;
    }

    this.listeners.forEach((listener) => listener(null));
  }

  public subscribe(listener: TokenProviderListener): () => void {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }
}

export { LocalStorageTokenProvider };
