import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import { FetchCustomerProfileQuery } from '@customer-booking/__generated__/graphql';

export const FETCH_CUSTOMER_PROFILE = gql(/* GraphQL */ `
  query FetchCustomerProfile {
    customer {
      id @client
      profile {
        firstName
        lastName
        email
        id
        phoneNumberValid
        companies {
          infos {
            id
            name
            companyAdmin
            useProBookingPage
            bookForOtherAccount
          }
        }
      }
      passengers {
        default {
          id
        }
      }
    }
  }
`);

export const useFetchCustomerProfileLazy = (
  options?: LazyQueryHookOptions<
    FetchCustomerProfileQuery,
    {
      [key: string]: never;
    }
  >
) => {
  const query = useLazyQuery(FETCH_CUSTOMER_PROFILE, {
    ...options,
  });

  return query;
};
