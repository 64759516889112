import { twMerge } from 'tailwind-merge';

export interface CalloutProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'info' | 'positive' | 'negative' | 'default' | 'warning';
  size?: 'sm' | 'md' | 'lg';
}

const sizes = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-3 px-5 text-base',
  lg: 'py-4 px-6 text-lg',
};

export const Callout = ({
  variant = 'default',
  className,
  size = 'md',
  ...props
}: CalloutProps) => {
  return (
    <p
      className={twMerge(
        `p-4 rounded-md border ${
          variant === 'info'
            ? 'bg-blue-50'
            : variant === 'positive'
            ? 'bg-green-50'
            : variant === 'negative'
            ? 'bg-red-50'
            : variant === 'warning'
            ? 'bg-yellow-50'
            : 'bg-primary/10'
        } ${
          variant === 'info'
            ? 'text-blue-800'
            : variant === 'positive'
            ? 'text-green-800'
            : variant === 'negative'
            ? 'text-red-800'
            : variant === 'warning'
            ? 'text-yellow-800'
            : 'text-primary'
        } ${
          variant === 'info'
            ? 'border-blue-200'
            : variant === 'positive'
            ? 'border-green-200'
            : variant === 'negative'
            ? 'border-red-200'
            : variant === 'warning'
            ? 'border-yellow-200'
            : 'border-primary'
        }`,
        sizes[size],
        className
      )}
      {...props}
    />
  );
};
