import * as React from 'react';

type Token = string;

interface Options {
  action: string;
  sitekey?: string;
}

interface Enterprise {
  ready: (callback: () => void) => void;
  execute: (site_key: string, options: Options) => Promise<Token>;
  render: (element: HTMLElement, options: Options) => Promise<Token>;
}

interface Grecaptcha {
  enterprise: Enterprise;
}

declare let grecaptcha: Grecaptcha;

export const useCaptcha = (siteKey: string) => {
  const [isExecuting, setIsExecuting] = React.useState(false);
  const [instance, setInstance] = React.useState<Enterprise | undefined>();

  React.useEffect(() => {
    try {
      grecaptcha.enterprise.ready(() => {
        setInstance(grecaptcha.enterprise);
      });
    } catch (err) {
      // gracefully handle error
      return;
    }
  });

  const execute = React.useCallback(
    async (options: Options) => {
      setIsExecuting(true);

      try {
        return await instance?.execute(siteKey, options);
      } catch (error) {
        console.log(error);
        return null;
      } finally {
        setIsExecuting(false);
      }
    },
    [instance]
  );

  const render = React.useCallback(
    (element: HTMLElement, options: Options) => {
      return instance?.render(element, { ...options, sitekey: siteKey });
    },
    [instance]
  );

  return { instance, execute, render, isExecuting };
};
