import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon, MinusSmallIcon } from '@hermes/icons/simple';
import { twMerge } from 'tailwind-merge';

export type CheckboxProps = CheckboxPrimitive.CheckboxProps;
export type CheckedState = CheckboxPrimitive.CheckedState;

const CheckMark = React.forwardRef(function CheckMarkInner(
  {
    checked,
  }: {
    checked: CheckboxPrimitive.CheckedState | undefined;
  },
  ref: React.ForwardedRef<SVGSVGElement>
) {
  if (!checked) return null;

  if (checked === 'indeterminate')
    return (
      <MinusSmallIcon
        ref={ref}
        className="fill-transparent stroke-white"
        strokeWidth={3.5}
      />
    );

  return (
    <CheckIcon
      ref={ref}
      className="fill-transparent stroke-white"
      strokeWidth={3.5}
    />
  );
});

export const Checkbox = React.forwardRef(
  (
    {
      checked: checkedProp,
      className,
      onCheckedChange,
      ...props
    }: CheckboxProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const [checked, setChecked] =
      React.useState<CheckboxPrimitive.CheckedState>(checkedProp ?? false);

    React.useEffect(() => {
      if (checkedProp !== undefined) setChecked(checkedProp);
    }, [checkedProp]);

    const handleCheckedChange = (checked: CheckboxPrimitive.CheckedState) => {
      setChecked(checked);
      onCheckedChange?.(checked);
    };

    return (
      <CheckboxPrimitive.Root
        ref={ref}
        onCheckedChange={handleCheckedChange}
        checked={checked}
        className={twMerge(
          'size-4 border-2 border-gray-400 rounded-sm shadow-sm',
          'hover:border-gray-500 active:scale-90 transition-all',
          'data-[state=checked]:bg-primary data-[state=checked]:border-primary-dark/30 duration-400',
          'data-[state=indeterminate]:bg-primary data-[state=indeterminate]:border-primary-dark/30 ',
          'focus-ring-visible',
          className
        )}
        {...props}
      >
        <CheckboxPrimitive.Indicator>
          <CheckMark checked={checked} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    );
  }
);
