import * as React from 'react';
import GlMap, {
  MapRef,
  Marker,
  Layer,
  Source,
  NavigationControl,
  AttributionControl,
} from 'react-map-gl';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeUniqueId } from '@apollo/client/utilities';

import 'mapbox-gl/dist/mapbox-gl.css';

import { FlagIcon, LocateIcon } from '@hermes/icons/simple';
import { Popover, Spinner } from '@hermes/ui';
import {
  useCaptcha,
  useGetPosition,
  useWatchPosition,
} from '@hermes/utils/hooks';
import { createContext } from '@hermes/utils/context';

import { PathLayer } from './PathLayer';
import { useFetchRideDirections } from '../../api';
import { useMapStore } from '../../state/store';
import { MapRideStop } from '../../types';

interface MapContextValue {
  fitToBounds: (
    bounds: [LngLatLike, LngLatLike],
    options?: {
      padding?: number;
      duration?: number;
    }
  ) => void;
  zoomToPlace: (lngLat: [number, number], zoom?: number) => void;
}

const [useMap, MapProvider] = createContext<MapContextValue>();

const PARIS = [48.855261, 2.346719] as const;
const FRANCE = new LngLatBounds({ lng: -8, lat: 41 }, { lng: 15, lat: 52 });

const Description = ({ name }: { name: string }) => {
  const [primary, secondary] = name.split(' – ');

  return (
    <p>
      <strong>{primary}</strong>
      <br />
      {secondary}
    </p>
  );
};

interface MarkerProps extends MapRideStop {
  hasDescription?: boolean;
}

const DropOffMarker = (props: MarkerProps) => {
  return (
    <Marker latitude={props.lat} longitude={props.long}>
      <Popover.Root open={props.hasDescription}>
        <Popover.Anchor className="relative">
          <div className="size-3">
            <div className="absolute -left-1 -bottom-1 size-3 rounded-full bg-primary-dark border-2 border-white shadow-md" />
            <FlagIcon className="bottom-0 absolute fill-primary-dark stroke-primary-dark size-6" />
          </div>
        </Popover.Anchor>
        <Popover.Content
          className="p-2 text-primary"
          side="right"
          align="center"
          sideOffset={30}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <Description name={props.name} />
        </Popover.Content>
      </Popover.Root>
    </Marker>
  );
};

const PickupMarker = (props: MarkerProps) => {
  return (
    <Marker latitude={props.lat} longitude={props.long}>
      <Popover.Root open={props.hasDescription}>
        <Popover.Anchor className="relative">
          <div className="size-5 rounded-full bg-primary-dark border-4 border-white shadow-md " />
        </Popover.Anchor>
        <Popover.Content
          className="p-2 text-primary"
          side="right"
          align="center"
          onOpenAutoFocus={(e) => e.preventDefault()}
          sideOffset={20}
        >
          <Description name={props.name} />
        </Popover.Content>
      </Popover.Root>
    </Marker>
  );
};

const StopMarker = (props: MarkerProps & { index: number }) => {
  return (
    <Marker latitude={props.lat} longitude={props.long}>
      <Popover.Root open={props.hasDescription}>
        <Popover.Trigger>
          <div className="text-center">
            <div className="size-5 rounded-full bg-primary-dark border-4 border-white shadow-md " />
          </div>
        </Popover.Trigger>
        <Popover.Content
          className="p-2"
          side="right"
          align="center"
          sideOffset={20}
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <FormattedMessage
            description="Stop {index} count"
            defaultMessage="Stop n°{index}"
            values={{
              index: props.index + 1,
            }}
          />
          <Description name={props.name} />
        </Popover.Content>
      </Popover.Root>
    </Marker>
  );
};

interface Feature {
  type: 'Feature';
  properties: {
    type: 'car' | 'walk';
    distance?: number | null;
    duration?: number | null;
  };
  geometry: {
    type: 'LineString';
    coordinates: number[][];
  };
  id: string;
}

interface MapProps {
  children?: React.ReactNode;
}

const Root = ({ children }: MapProps) => {
  const { position } = useGetPosition();

  const { pickUp, dropOff } = useMapStore((state) => ({
    pickUp: state.pickUp,
    dropOff: state.dropOff,
    stops: state.stops,
  }));

  const [latitude, longitude] = position ?? PARIS;
  const mapRef = React.useRef<MapRef>(null);

  const zoomToPlace = React.useCallback(
    ([lng, lat]: [number, number], zoom = 14) => {
      mapRef.current?.flyTo({
        animate: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        center: [lng, lat],
        zoom,
      });
    },
    [mapRef]
  );

  const fitToBounds = React.useCallback(
    (
      bounds: [LngLatLike, LngLatLike],
      { padding = 50, duration = 1000 } = {}
    ) => {
      mapRef.current?.fitBounds(bounds, {
        animate: false,
        padding,
        duration,
      });
    },
    [mapRef]
  );

  React.useEffect(() => {
    if (mapRef.current && pickUp && !dropOff) {
      zoomToPlace([pickUp.long, pickUp.lat]);
    }
  }, [zoomToPlace, pickUp, dropOff]);

  React.useEffect(() => {
    if (mapRef.current && dropOff && !pickUp) {
      zoomToPlace([dropOff.long, dropOff.lat]);
    }
  }, [zoomToPlace, dropOff, pickUp]);

  return (
    <MapProvider value={{ fitToBounds, zoomToPlace }}>
      <GlMap
        ref={mapRef}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
        minZoom={5}
        renderWorldCopies={false}
        maxBounds={FRANCE}
        dragRotate={false}
        attributionControl={false}
        initialViewState={{
          longitude,
          latitude,
          zoom: 13,
        }}
        // mapStyle="https://api.maptiler.com/maps/basic-v2/style.json?key=8U9aOk3ikZIHk26bofuz"
        mapStyle="mapbox://styles/slvn/clnuc565c00os01p96musg1tc"
      >
        {children}
      </GlMap>
    </MapProvider>
  );
};

const Markers = ({ hasDescription = true }: { hasDescription?: boolean }) => {
  const { pickUp, dropOff, stops } = useMapStore((state) => ({
    pickUp: state.pickUp,
    dropOff: state.dropOff,
    stops: state.stops,
  }));

  return (
    <>
      {pickUp && <PickupMarker {...pickUp} hasDescription={hasDescription} />}
      {dropOff && (
        <DropOffMarker {...dropOff} hasDescription={hasDescription} />
      )}
      {stops?.map((stop, i) => (
        <StopMarker
          key={`${stop.lat},${stop.long}`}
          {...stop}
          index={i}
          hasDescription={hasDescription}
        />
      ))}
    </>
  );
};

const Features = ({ padding = 100 }: { padding?: number }) => {
  const { fitToBounds } = useMap();
  const [features, setFeatures] = React.useState<Feature[]>([]);
  const [token, setToken] = React.useState<string | null>(null);

  const { execute } = useCaptcha(
    process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_SITE_KEY as string
  );

  const { pickUp, dropOff, stops } = useMapStore((state) => ({
    pickUp: state.pickUp,
    dropOff: state.dropOff,
    stops: state.stops,
  }));

  const ride = React.useMemo(() => {
    const ride = [];
    if (pickUp) ride.push(pickUp);
    if (stops) ride.push(...stops);
    if (dropOff) ride.push(dropOff);
    return ride;
  }, [pickUp, stops, dropOff]);

  React.useEffect(() => {
    if (ride) {
      setFeatures([]);
    }
  }, [ride]);

  const { data: directionsData } = useFetchRideDirections(
    {
      origin: `${pickUp?.lat},${pickUp?.long}`,
      destination: `${dropOff?.lat},${dropOff?.long}`,
      waypoints: stops.map((stop) => `${stop.lat},${stop.long}`),
    },
    {
      skip: !pickUp || !dropOff || !token,
      context: {
        headers: {
          'x-recaptcha-token': token,
        },
      },
      onCompleted: (data) => {
        const walkFeatures: Feature[] = [];
        const carFeatures: Feature[] = data.place.directions.features.map(
          (feature) => ({
            type: 'Feature' as const,
            properties: {
              ...feature.properties,
              type: 'car' as const,
            },
            id: makeUniqueId('feature'),
            geometry: {
              type: 'LineString' as const,
              ...feature.geometry,
            },
          })
        );

        ride.forEach((leg, index) => {
          const isLast = index === ride.length - 1;

          const coordinates = isLast
            ? carFeatures[carFeatures.length - 1].geometry.coordinates[
                carFeatures[carFeatures.length - 1].geometry.coordinates
                  .length - 1
              ]
            : carFeatures[index].geometry.coordinates[0];

          walkFeatures.push({
            type: 'Feature' as const,
            properties: {
              type: 'walk' as const,
            },
            geometry: {
              type: 'LineString' as const,
              coordinates: [[leg.long, leg.lat], coordinates],
            },
            id: makeUniqueId('feature'),
          });

          setFeatures([...walkFeatures, ...carFeatures]);
        });
      },
    }
  );

  React.useEffect(() => {
    const getToken = async () => {
      const token = await execute({ action: 'place/directions' });
      if (token) setToken(token);
    };

    getToken();
  }, [execute, pickUp, dropOff, stops]);

  React.useEffect(() => {
    if (directionsData) {
      const walkFeatures: Feature[] = [];
      const carFeatures: Feature[] =
        directionsData.place.directions.features.map((feature) => ({
          type: 'Feature' as const,
          properties: {
            ...feature.properties,
            type: 'car' as const,
          },
          id: makeUniqueId('feature'),
          geometry: {
            type: 'LineString' as const,
            ...feature.geometry,
          },
        }));

      const ride = [];
      if (pickUp) ride.push(pickUp);
      if (stops) ride.push(...stops);
      if (dropOff) ride.push(dropOff);

      ride.forEach((leg, index) => {
        const isLast = index === ride.length - 1;

        const coordinates = isLast
          ? carFeatures[carFeatures.length - 1].geometry.coordinates[
              carFeatures[carFeatures.length - 1].geometry.coordinates.length -
                1
            ]
          : carFeatures[index].geometry.coordinates[0];

        walkFeatures.push({
          type: 'Feature' as const,
          properties: {
            type: 'walk' as const,
          },
          geometry: {
            type: 'LineString' as const,
            coordinates: [[leg.long, leg.lat], coordinates],
          },
          id: makeUniqueId('feature'),
        });

        setFeatures([...walkFeatures, ...carFeatures]);
      });
    }
  }, [directionsData, pickUp, dropOff, stops]);

  React.useEffect(() => {
    if (directionsData && dropOff && pickUp) {
      const lngLat: [number, number][] = [
        [pickUp.long, pickUp.lat],
        [dropOff.long, dropOff.lat],
        directionsData?.place.directions.properties.bounds?.southwest as [
          number,
          number
        ],
        directionsData?.place.directions.properties.bounds?.northeast as [
          number,
          number
        ],
      ];

      const bounds = lngLat.reduce(
        (bounds, lngLat) => bounds.extend(lngLat),
        new LngLatBounds()
      );

      fitToBounds(
        [
          bounds.getSouthWest().toArray() as [number, number],
          bounds.getNorthEast().toArray() as [number, number],
        ],
        {
          padding,
          duration: 400,
        }
      );
    }
  }, [fitToBounds, directionsData, dropOff, padding, pickUp]);

  return (
    <>
      {features?.map((feature) => {
        if (feature.properties.type === 'walk') {
          return (
            <Source
              key={feature.id}
              id={feature.id}
              type="geojson"
              data={feature}
            >
              <PathLayer
                id={`layer-${feature.id}`}
                sourceId={feature.id}
                dashed
              />
            </Source>
          );
        }

        return (
          <Source
            id={feature.id}
            type="geojson"
            data={feature}
            key={feature.id}
          >
            <Layer
              id={`layer-${feature.id}`}
              type="line"
              source={feature.id}
              layout={{
                'line-join': 'round',
                'line-cap': 'round',
              }}
              paint={{
                'line-color': '#1B405F',
                'line-width': 5,
              }}
            />
          </Source>
        );
      })}
    </>
  );
};

const UserPosition = () => {
  const { enabled } = useGetPosition();
  const { position } = useWatchPosition({ enabled });

  if (!position) {
    return null;
  }

  return (
    <Marker
      latitude={position.coords.latitude}
      longitude={position.coords.longitude}
    >
      <div className="absolute -left-2 -bottom-2 size-4 rounded-full bg-blue-500 border-2 border-white" />
    </Marker>
  );
};

const Controls = () => {
  const { formatMessage } = useIntl();
  const { zoomToPlace } = useMap();
  const { getPosition, position, loading } = useGetPosition();

  const locateMe = () => {
    if (position) {
      const [latitude, longitude] = position;
      zoomToPlace([longitude, latitude]);
    } else {
      getPosition();
    }
  };

  React.useEffect(() => {
    if (position) {
      const [latitude, longitude] = position;
      zoomToPlace([longitude, latitude]);
    }
  }, [zoomToPlace, position]);

  return (
    <>
      <AttributionControl position="bottom-right" compact />
      <NavigationControl position="bottom-right" />
      <div className="absolute top-[70px] md:top-auto md:bottom-[130px] right-2 z-10">
        <button
          className="p-2 rounded-md bg-white shadow-sm"
          onClick={locateMe}
          disabled={Boolean(loading)}
          aria-label={formatMessage({
            description: 'Locate me button',
            defaultMessage: 'Me localiser',
          })}
        >
          {loading ? (
            <Spinner variant="primary" size="sm" inverted />
          ) : (
            <LocateIcon className="fill-primary-dark stroke-transparent size-4" />
          )}
        </button>
      </div>
    </>
  );
};

export const Map = {
  Controls,
  Root,
  Markers,
  Features,
  UserPosition,
};
