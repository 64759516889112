import { Observable } from '@apollo/client';

export const promiseToObservable = <T>(promise: Promise<T>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new Observable((subscriber: any) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });
