// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = <T extends Function>(fn: T, delay = 300) => {
  let timer: NodeJS.Timeout | null = null;

  const debouncedFn = (...args: unknown[]) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      fn(...args);
      timer = null;
    }, delay);
  };

  return debouncedFn as unknown as T;
};
