import * as React from 'react';
import { SVGProps } from 'react';
const SvgBusinessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 66 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.195}
      d="M57.786 16.897s5.923-.074 6.513-.962c.593-.89.296-4.887-.96-5.998-1.26-1.11-9.624-2.96-16.507-3.33-4.886-2.886-8.516-4.812-14.806-5.034-5.254-.186-13.251.518-21.245 3.256-3.186.889-8.513 2.593-8.513 2.593v3.256c0 .416-.89.843-.89 1.26v3.182c0 1.407 2.368 1.997 5.035 1.997 3.182 0 3.775-.071 3.775-.071m8.419-.075H48.68l.903-.008m-14.228 3.595h-6.564l-4.977 6.824h16.521l-4.98-6.824Zm-16.76-4.749a4.188 4.188 0 1 1-8.376.001 4.188 4.188 0 0 1 8.375 0Zm-2.428 0a1.762 1.762 0 0 1-3.521 0 1.762 1.762 0 0 1 3.521 0Zm4.533-9.276c4.809 0 22.986.31 22.986.31-3.718-2.074-6.852-3.322-11.724-3.495-2.108-.08-8.912-.103-16.932 2.045.633.77.858 1.14 5.67 1.14Zm37.128 9.276a4.188 4.188 0 1 1-8.375.001 4.188 4.188 0 0 1 8.375 0Zm-2.427 0a1.762 1.762 0 0 1-3.522 0 1.762 1.762 0 0 1 3.522 0Z"
    />
  </svg>
);
export default SvgBusinessIcon;
