import { TokenProvider, TokenProviderListener } from './TokenProvider.abstract';

class InMemoryTokenProvider implements TokenProvider {
  private token: string | undefined;
  private listeners = new Set<TokenProviderListener>();

  public getToken(): string | undefined {
    return this.token;
  }

  public setToken(token: string): void {
    this.token = token;
    this.listeners.forEach((listener) => listener(token));
  }

  public clearToken(): void {
    this.token = undefined;
    this.listeners.forEach((listener) => listener(null));
  }

  public subscribe(listener: TokenProviderListener): () => void {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }
}

export { InMemoryTokenProvider };
