import * as React from 'react';
import Script from 'next/script';
import { FormattedMessage } from 'react-intl';
import { twJoin } from 'tailwind-merge';
import Image from 'next/image';
import googleLogo from './google-logo.png';

import { useLocale } from '@customer-booking/features/l10n';
import { Button } from '@hermes/ui';

declare global {
  interface Window {
    gapi: {
      load: (name: string, callback: () => void) => void;
      auth2: {
        init: (config: { client_id: string }) => {
          attachClickHandler: (
            element: HTMLButtonElement,
            options: unknown,
            onSuccess: (googleUser: {
              getBasicProfile: () => {
                getGivenName: () => string | undefined;
                getFamilyName: () => string | undefined;
                getEmail: () => string | undefined;
              };
              getAuthResponse: () => { id_token: string };
            }) => void,
            onFailure: (error: unknown) => void
          ) => void;
        };
      };
    };
  }
}

export type GoogleSignInSuccessHandler = (
  googleAccessToken: string,
  user: {
    firstName: string;
    lastName: string;
    email: string;
  }
) => void;

export interface GoogleSignInProps {
  onSignInSuccess: GoogleSignInSuccessHandler;
  type?: 'sign-in' | 'continue' | 'sign-up';
}

export const GoogleSignIn = ({ onSignInSuccess, type }: GoogleSignInProps) => {
  const { locale } = useLocale();
  const googleButtonRef = React.useRef<HTMLButtonElement>(null);

  const handleInitGoogleID = () => {
    window.gapi.load('auth2', () => {
      if (!googleButtonRef.current) {
        return;
      }

      const auth2 = window.gapi.auth2.init({
        client_id: `${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
      });

      auth2.attachClickHandler(
        googleButtonRef.current,
        {},
        (googleUser) => {
          const profile = googleUser.getBasicProfile();
          const user = {
            firstName: profile.getGivenName() ?? '',
            lastName: profile.getFamilyName() ?? '',
            email: profile.getEmail() ?? '',
          };

          onSignInSuccess(googleUser.getAuthResponse().id_token, user);
        },
        (error: unknown) => {
          console.log(JSON.stringify(error, undefined, 2));
        }
      );
    });
  };

  return (
    <div className="flex items-center justify-center">
      <Script
        // use key to force mount-unmount on locale change
        key={locale}
        src={`https://apis.google.com/js/api:client.js`}
        onReady={handleInitGoogleID}
      />

      <Button
        ref={googleButtonRef}
        className={twJoin(
          'flex h-[40px] items-center justify-center gap-2 bg-white text-gray-600 w-full hover:bg-white border border-gray-200 rounded-full'
        )}
      >
        <Image src={googleLogo} alt="Google Logo" width={18} height={18} />
        {type === 'continue' && (
          <FormattedMessage
            description="Continue with Google"
            defaultMessage="Continuer avec Google"
          />
        )}
        {type === 'sign-in' && (
          <FormattedMessage
            description="Sign in with Google"
            defaultMessage="Se connecter avec Google"
          />
        )}
        {type === 'sign-up' && (
          <FormattedMessage
            description="Sign up with Google"
            defaultMessage="S'inscrire avec Google"
          />
        )}
      </Button>
    </div>
  );
};
