import * as React from 'react';
import { SVGProps } from 'react';
const SvgAirplaneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M20.5 10.537C20.5 6.514 22.833 4.503 24 4c1.167.503 3.5 2.514 3.5 6.537v7.543L43 31v4l-16-8v9l5 8-8-3-8 3 5-8v-9L5 35v-4l15.5-12.92v-7.543Z"
    />
  </svg>
);
export default SvgAirplaneIcon;
