import * as React from 'react';
import { SVGProps } from 'react';
const SvgHybridIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 65 33" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.195}
      d="m50.978 4.204 13.085 12.01M17.09 27.937H41.58m8.713 0h4.066c2.702 0 2.702-2.456 2.702-2.456v-2.783c0-1.525-.607-2.006-.607-2.006s-1.481-1.906-2.24-2.142c-1.04-.32-8.867-2.714-8.867-2.714s-7.258-2.886-10.962-4.383c-2.588-1.079-5.091-1.245-5.963-1.268a54.447 54.447 0 0 0-6.23.302h-.002c-1.613.114-5.507.695-7.846 1.62-1.96.774-7.727 3.579-7.727 3.579s-2.147.66-3.72 1.154c-1.295.402-1.531 1.094-1.531 1.71v3.865c-.003.01-.003.022-.003.032v2.92c0 .61.443 1.127 1.047 1.215l6.047.893m51.39-23.308a6.022 6.022 0 0 1 .365 8.512 6.022 6.022 0 0 1-8.513.365C49.245 10.796 47.67 1.17 47.67 1.17s9.723.745 12.176 2.997ZM40.31 16.047a447.94 447.94 0 0 0-6.78-2.758c-2.286-.921-4.053-1.107-4.814-1.144a54.665 54.665 0 0 0-4.151.09l1.626 5.602h14.434c.808 0 1.082-1.199-.315-1.79Zm-16.141 1.79-1.582-5.444s-3.057.176-6.305 1.072c-1.494.416-2.384.755-2.887 1.07-.673.453.318 1.16.761 1.55.387.34 1.46 1.402 2.233 1.682.23.044.459.07.682.07h7.098Zm-6.88 9.393a4.454 4.454 0 0 1-4.457 4.456 4.456 4.456 0 1 1 4.456-4.456Zm-2.585-.604a1.872 1.872 0 1 1-3.745.004 1.872 1.872 0 0 1 3.744-.004Zm35.786 0a4.456 4.456 0 1 1-8.913 0 4.456 4.456 0 0 1 8.913 0Zm-2.585 0a1.872 1.872 0 1 1-3.745.004 1.872 1.872 0 0 1 3.745-.004Z"
    />
  </svg>
);
export default SvgHybridIcon;
