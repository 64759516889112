import '../globals.css';

import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import * as React from 'react';
import { NextPage } from 'next';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import Script from 'next/script';
import { IntlConfig, IntlProvider, ReactIntlErrorCode } from 'react-intl';
import Head from 'next/head';

import {
  ErrorBoundary,
  ErrorMonitoringProvider,
} from '@marcel/error-monitoring';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
} from '@apollo/client';
import { NextRouter } from 'next/router';

import {
  TokenProvider,
  authHeaderLink,
  generateTokenLink,
  onUnauthorizedLink,
  createAuthContextLink,
} from '@customer-booking/features/auth';

import {
  CookieTokenProvider,
  InMemoryTokenProvider,
  LocalStorageTokenProvider,
} from '@customer-booking/features/auth/services';
import { PreviousRouteProvider } from '@customer-booking/state';

import { DEFAULT_LOCALE, parseLocale } from '@customer-booking/features/l10n';
import { lang } from '@customer-booking/features/l10n/lang';

import { AppErrorBoundaryFallback } from '@customer-booking/components/AppErrorBoundaryFallback';
import { Toasts, Tooltip } from '@hermes/ui';
import { AnalyticsProvider } from '@hermes/analytics';
import { GoogleAnalytics } from '@hermes/analytics/google';
import { I18nProvider } from '@react-aria/i18n';
import { env, looseEnv } from '@customer-booking/features/env';
import { paginationLink } from '@customer-booking/features/auth/links/paginationLink';

const accessTokenProvider = new InMemoryTokenProvider();
const refreshTokenProvider = new LocalStorageTokenProvider('refreshToken');
const cookieTokenProvider = new CookieTokenProvider('customer-booking-v2');

const tokenProviders = {
  accessTokenProvider,
  refreshTokenProvider,
  cookieTokenProvider,
};

const authContextLink = createAuthContextLink(tokenProviders);

const httpLink = new HttpLink({
  uri: env('GRAPHQL_API'),
});

const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  cache: new InMemoryCache({
    typePolicies: {
      Customer: {
        keyFields: ['id'],
        fields: {
          id: {
            read: () => 'customer',
          },
        },
      },
    },
  }),
  connectToDevTools: true,
  link: from([
    // set up the auth context link first to access the tokens
    authContextLink,
    // handle unauthorized errors by refreshing the token
    onUnauthorizedLink,
    // generate a token if no token is present using refresh token
    generateTokenLink,
    // add the authorization header to the request
    authHeaderLink,
    paginationLink,
    httpLink,
  ]),
});

interface DefaultPageProps {
  router: NextRouter;
}

export type NextPageWithLayout<
  P extends object | undefined = Record<string, never>,
  IP = P
> = NextPage<P & DefaultPageProps, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const errorMonitoringConfig = (() => {
  try {
    return {
      accessToken: env('ROLLBAR_ACCESS_TOKEN'),
      environment: env('ENVIRONMENT'),
      codeVersion: env('VERSION'),
    };
  } catch (error) {
    console.info('Error monitoring is inactive');
    return undefined;
  }
})();

const initAwsRum = () => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: Number(looseEnv('RUM_SESSION_SAMPLE_RATE') ?? 1),
      guestRoleArn: looseEnv('GUEST_ROLE_ARN'),
      identityPoolId: looseEnv('RUM_IDENDITY_POOL_ID'),
      endpoint: looseEnv('RUM_ENDPOINT'),
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    };

    const APPLICATION_ID = looseEnv('RUM_APPLICATION_ID');
    const APPLICATION_VERSION = looseEnv('VERSION');
    const APPLICATION_REGION = looseEnv('APPLICATION_REGION');

    if (!APPLICATION_ID || !APPLICATION_VERSION || !APPLICATION_REGION) {
      return null;
    }

    return new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    console.info('RUM is inactive');
    // gracefully handle the error
    return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars, @typescript-eslint/no-empty-function
export function reportWebVitals(metric: NextWebVitalsMetric) {}

const analytics = new GoogleAnalytics(looseEnv('GOOGLE_TAG_MANAGER_ID'));

function CustomApp({ Component, pageProps, router }: AppPropsWithLayout) {
  const awsRum = React.useRef<AwsRum | null>(null);

  if (!awsRum.current && typeof window !== 'undefined') {
    awsRum.current = initAwsRum();
  }

  const locale = parseLocale(router.query.locale as string);
  const messages = lang[locale];

  const handleTranslationError: IntlConfig['onError'] = (error) => {
    if (error.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
      return;
    }
    throw error;
  };

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  React.useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      analytics?.trackEvent('ngRouteChange', {
        current_url: router.asPath,
      });
    });
  }, [router]);

  React.useEffect(() => {
    console.log(`Version ${env('VERSION')}`);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="description"
          content="Ne vous privez plus d'un vrai chauffeur privé. Réservez une voiture avec chauffeur 7j/7 et 24H/24 à Paris et dans 64 villes de France dès maintenant."
        ></meta>
      </Head>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${env(
          'GOOGLE_CAPTCHA_SITE_KEY'
        )}`}
      />
      <AnalyticsProvider value={analytics}>
        <ErrorMonitoringProvider config={errorMonitoringConfig}>
          <ErrorBoundary fallbackUI={AppErrorBoundaryFallback}>
            <Toasts>
              <ErrorBoundary>
                <ApolloProvider client={client}>
                  <IntlProvider
                    messages={messages}
                    locale={locale}
                    defaultLocale={DEFAULT_LOCALE}
                    onError={handleTranslationError}
                  >
                    <I18nProvider locale={locale}>
                      <TokenProvider value={tokenProviders}>
                        <PreviousRouteProvider>
                          <Tooltip.Provider>
                            {getLayout(
                              <Component {...pageProps} router={router} />
                            )}
                          </Tooltip.Provider>
                        </PreviousRouteProvider>
                      </TokenProvider>
                    </I18nProvider>
                  </IntlProvider>
                </ApolloProvider>
              </ErrorBoundary>
            </Toasts>
          </ErrorBoundary>
        </ErrorMonitoringProvider>
      </AnalyticsProvider>
    </>
  );
}

export default CustomApp;
