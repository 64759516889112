import * as React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { twMerge } from 'tailwind-merge';

const DropdownMenuRoot = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const animationClasses =
  'will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade';

const DropdownMenuContent = ({
  className,
  ...props
}: DropdownMenuPrimitive.DropdownMenuContentProps) => {
  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        className={twMerge(
          `p-1 z-10 bg-white rounded-md shadow-menu ${animationClasses} maw-h-[600px] overflow-y-auto`,
          className
        )}
        {...props}
      />
    </DropdownMenuPrimitive.Portal>
  );
};

const DropdownMenuItem = ({
  className,
  ...props
}: DropdownMenuPrimitive.DropdownMenuItemProps) => {
  return (
    <DropdownMenuPrimitive.Item
      className={twMerge(
        'group text-primary rounded-sm flex items-center px-4 py-2 cursor-pointer transition-colors duration-150 outline-none',
        'data-[disabled]:text-primary/40 data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-100',
        className
      )}
      {...props}
    />
  );
};

const DropdownMenuSeparator = ({
  className,
  ...props
}: DropdownMenuPrimitive.DropdownMenuSeparatorProps) => {
  return (
    <DropdownMenuPrimitive.Separator
      className={twMerge('h-[1px] bg-primary/30 m-2', className)}
      {...props}
    />
  );
};

const DropdownMenuArrow = (
  props: DropdownMenuPrimitive.DropdownMenuArrowProps
) => {
  return <DropdownMenuPrimitive.Arrow className="fill-white" {...props} />;
};

export const DropdownMenu = {
  Root: DropdownMenuRoot,
  Trigger: DropdownMenuTrigger,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  Separator: DropdownMenuSeparator,
  Arrow: DropdownMenuArrow,
};
