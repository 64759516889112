import { useLocale } from '@react-aria/i18n';
import { useCalendar, AriaCalendarProps } from '@react-aria/calendar';
import { useCalendarState } from '@react-stately/calendar';
import { createCalendar, DateValue } from '@internationalized/date';

import { ChevronLeftIcon, ChevronRightIcon } from '@hermes/icons/simple';

import { CalendarGrid } from './CalendarGrid';

export interface CalendarProps<T extends DateValue>
  extends AriaCalendarProps<T> {
  months?: 1 | 2;
}

export function Calendar<T extends DateValue>({
  months = 1,
  ...props
}: CalendarProps<T>) {
  const { locale } = useLocale();

  const state = useCalendarState({
    ...props,
    visibleDuration: { months },
    locale,
    createCalendar,
  });

  const calendar = useCalendar(props, state);

  const { calendarProps, title } = calendar;

  const {
    onPress: onPrevPress,
    isDisabled,
    ...prevButtonProps
  } = calendar.prevButtonProps;
  const { onPress: onNextPress, ...nextButtonProps } = calendar.nextButtonProps;

  return (
    <div {...calendarProps}>
      <div className="flex items-center py-4 px-2 gap-2">
        <button
          onClick={() => state.focusPreviousPage()}
          className="group"
          disabled={isDisabled}
          {...prevButtonProps}
        >
          <ChevronLeftIcon className="size-6 stroke-primary fill-transparent group-disabled:stroke-gray-200" />
        </button>
        <h2 className="text-xl text-primary flex-1 text-center">{title}</h2>
        <button onClick={() => state.focusNextPage()} {...nextButtonProps}>
          <ChevronRightIcon className="size-6 stroke-primary fill-transparent" />
        </button>
      </div>
      <div className="flex gap-6">
        <CalendarGrid state={state} locale={locale} />
        {months === 2 && (
          <CalendarGrid state={state} locale={locale} offset={{ months: 1 }} />
        )}
      </div>
    </div>
  );
}
