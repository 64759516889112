import * as React from 'react';
import { SVGProps } from 'react';
const SvgClientError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 157 170"
    {...props}
  >
    <path
      fill="#fff"
      d="M103.557 168.505H2l8.875-37.354c.719-3.026 3.807-5.422 6.88-5.422h70.047c3.078 0 6.161 2.396 6.88 5.422"
    />
    <path
      stroke="#1D5E7C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2.667}
      d="M103.557 168.505H2l8.875-37.354c.719-3.026 3.807-5.422 6.88-5.422h70.047c3.078 0 6.161 2.396 6.88 5.422l8.875 37.354Z"
    />
    <path
      fill="#fff"
      d="M80.328 43.063H52.562c-5.171 0-9.4 4.416-9.4 9.812v40.646c0 .885.12 1.745.338 2.562l-5.74.771v28.448h-8.546c0 11.88 11.484 21.505 25.645 21.505 14.162 0 25.651-9.625 25.651-21.505H68.885v-21.974h11.443c5.172 0 9.406-4.411 9.406-9.807V49.104c0-5.396-4.234-6.041-9.406-6.041Z"
    />
    <path
      stroke="#7CBED3"
      strokeMiterlimit={10}
      strokeWidth={2.667}
      d="M80.328 43.063H52.562c-5.171 0-9.4 4.416-9.4 9.812v40.646c0 .885.12 1.745.338 2.562l-5.74.771v28.448h-8.546c0 11.88 11.484 21.505 25.645 21.505 14.162 0 25.651-9.625 25.651-21.505H68.885v-21.974h11.443c5.172 0 9.406-4.411 9.406-9.807V49.104c0-5.396-4.234-6.041-9.406-6.041Z"
    />
    <path
      fill="#1B2D3C"
      d="M51.307 105.932c0 17.162 1.12 25.948-15.026 25.948V46a23.3 23.3 0 0 1-4.536.443c-12.823 0-23.214-10.401-23.214-23.214C8.531 10.401 18.921 0 31.745 0c12.828 0 23.213 10.401 23.213 23.23a23.72 23.72 0 0 1-.411 4.385 23.077 23.077 0 0 1 5.026-.547h8.469c12.734 0 22.88 10.333 22.88 23.078-14.693 14.682-35.63 20.812-39.615 21.88"
    />
    <path
      fill="#fff"
      d="M95.422 161.49h52.182c6.042 0 9.823-6.526 6.823-11.771l-26.047-45.511c-3.021-5.276-10.625-5.28-13.651-.01l-26.13 45.51c-3.01 5.245.77 11.782 6.823 11.782Z"
    />
    <path
      stroke="#EC6A6A"
      strokeMiterlimit={10}
      strokeWidth={2.667}
      d="M95.422 161.49h52.182c6.042 0 9.823-6.527 6.823-11.771l-26.047-45.511c-3.021-5.276-10.625-5.281-13.651-.01l-26.13 45.51c-3.01 5.245.77 11.782 6.823 11.782Z"
    />
    <path
      fill="#EC6A6A"
      d="M125.172 146.656c0 1.016-.344 1.87-1.042 2.563-.687.692-1.547 1.041-2.562 1.041-1.016 0-1.87-.349-2.563-1.041-.693-.693-1.042-1.547-1.042-2.563 0-1.015.349-1.87 1.042-2.567.693-.693 1.547-1.042 2.563-1.042 1.015 0 1.875.349 2.562 1.042.698.697 1.042 1.552 1.042 2.567Zm-6.552-6.114-.771-19.276h7.13l-.771 19.276"
    />
  </svg>
);
export default SvgClientError;
