import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import {
  getCountries,
  getPhoneCode,
  AsYouType,
  CountryCode,
} from 'libphonenumber-js';
import { getCountryFlag } from '@hermes/utils/methods';
import { createContext } from '@hermes/utils/context';

import { Input as InputPrimitive, inputClasses } from './Input';
import { Select } from '../Select';

const formatNumber = (valueParam: string, countryCode: CountryCode) => {
  const typedPhoneNumber = new AsYouType(countryCode);
  const inputValue = typedPhoneNumber.input(valueParam);

  const formattedNumber = typedPhoneNumber.getNumber()?.formatNational();

  return {
    number: typedPhoneNumber.isValid() ? formattedNumber : inputValue,
    countryCode: typedPhoneNumber.getCountry() ?? countryCode,
    internationalNumber: typedPhoneNumber.getNumber()?.formatInternational(),
    isValid: typedPhoneNumber.isValid(),
  };
};

export interface PhoneNumber {
  number: string;
  countryCode: CountryCode;
  internationalNumber: string;
  isValid?: boolean;
}

interface PhoneNumberInputContext {
  phoneNumber: PhoneNumber;
  setPhoneNumber: (phoneNumber: Partial<PhoneNumber>) => void;
  onChange?: (phoneNumber: PhoneNumber) => void;
  ariaInvalid: boolean;
  ariaDescribedBy?: string;
  ariaLabel?: string;
}

const [usePhoneNumberInput, PhoneNumberInputProvider] =
  createContext<PhoneNumberInputContext>();

const Root = ({
  children,
  countryCode,
  onChange,
  value,
  'aria-invalid': ariaInvalid = false,
  'aria-describedby': ariaDescribedBy,
  'aria-label': ariaLabel,
  className,
}: {
  children: React.ReactNode;
  countryCode?: CountryCode;
  onChange?: (phoneNumber: PhoneNumber) => void;
  value?: PhoneNumber;
  'aria-invalid'?: boolean;
  'aria-describedby'?: string;
  'aria-label'?: string;
  className?: string;
}) => {
  const [phoneNumber, setPhoneNumber] = React.useState(
    value ?? {
      number: '',
      countryCode: countryCode ?? ('FR' as const),
      internationalNumber: '',
    }
  );

  const onChangeRef = React.useRef<PhoneNumberInputContext['onChange']>();
  onChangeRef.current = onChange;

  const updatePhoneNumber = React.useCallback(
    (phoneNumber: Partial<PhoneNumber>) => {
      setPhoneNumber((current) => ({ ...current, ...phoneNumber }));
    },
    []
  );

  React.useEffect(() => {
    if (value) {
      const { number, countryCode, internationalNumber } = formatNumber(
        value.number,
        value.countryCode
      );

      updatePhoneNumber({
        number: number ?? value.number,
        countryCode,
        internationalNumber,
      });
    }
  }, [updatePhoneNumber, value]);

  const context = React.useMemo(() => {
    return {
      phoneNumber,
      setPhoneNumber: updatePhoneNumber,
      onChange: onChangeRef.current,
      ariaInvalid,
      ariaDescribedBy,
      ariaLabel,
    };
  }, [phoneNumber, updatePhoneNumber, ariaInvalid, ariaDescribedBy, ariaLabel]);

  return (
    <PhoneNumberInputProvider value={context}>
      <div
        className={twMerge(
          inputClasses,
          'group flex p-0 w-min',
          ariaInvalid
            ? 'bg-red-100/20 border-red-500 focus-within:border-red-600 hover:border-red-600'
            : 'focus-within:border-primary hover:focus-within:border-primary',
          className
        )}
      >
        {children}
      </div>
    </PhoneNumberInputProvider>
  );
};

type InputProps = Omit<
  React.ComponentProps<typeof InputPrimitive>,
  'onChange' | 'value'
>;

const Input = ({ className, ...props }: InputProps) => {
  const {
    phoneNumber,
    setPhoneNumber,
    onChange,
    ariaInvalid,
    ariaDescribedBy,
    ariaLabel,
  } = usePhoneNumberInput();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { number, countryCode, internationalNumber, isValid } = formatNumber(
      e.target.value,
      phoneNumber.countryCode
    );

    const nextValue = number ?? new AsYouType().input(e.target.value);

    setPhoneNumber({ number, countryCode });

    onChange?.({
      number: nextValue,
      countryCode,
      internationalNumber: internationalNumber ?? nextValue,
      isValid,
    });
  };

  return (
    <InputPrimitive
      value={phoneNumber.number}
      onChange={handleInputChange}
      className={twMerge(
        'border-0 border-l rounded-md background-transparent border-none shadow-none',
        className
      )}
      aria-invalid={ariaInvalid}
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      {...props}
    />
  );
};

interface CountryCodeSelectProps {
  locale: string;
}

const CountryCodeSelect = ({ locale }: CountryCodeSelectProps) => {
  const { phoneNumber, setPhoneNumber, onChange } = usePhoneNumberInput();

  const regionNames = React.useMemo(
    () => new Intl.DisplayNames([locale], { type: 'region' }),
    [locale]
  );

  const countries = React.useMemo(
    () =>
      getCountries().map((countryCode) => ({
        countryCode: countryCode,
        phoneCode: getPhoneCode(countryCode),
        name: regionNames.of(countryCode),
      })),
    [regionNames]
  );

  const handleValueChange = (countryCode: CountryCode) => {
    setPhoneNumber({ countryCode });
    onChange?.({
      number: phoneNumber.number,
      countryCode,
      internationalNumber:
        phoneNumber.internationalNumber ?? phoneNumber.number,
      isValid: phoneNumber.isValid,
    });
  };

  return (
    <Select.Root
      value={phoneNumber.countryCode}
      onValueChange={handleValueChange}
    >
      <Select.Trigger className="flex items-center px-2 min-w-min border-0 border-r border-gray-200 group-focus-within:border-primary rounded-l-md rounded-r-none shadow-none">
        <Select.Value>{getCountryFlag(phoneNumber.countryCode)}</Select.Value>
      </Select.Trigger>
      <Select.Content>
        <Select.Viewport>
          {countries.map(({ countryCode, name }) => (
            <Select.Item
              key={countryCode}
              value={countryCode}
              className="flex gap-2"
            >
              {getCountryFlag(countryCode)}
              <Select.ItemText key={`${countryCode}-text`}>
                {name}
              </Select.ItemText>
            </Select.Item>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
};

export const PhoneNumberInput = {
  Root,
  Input,
  CountryCodes: CountryCodeSelect,
};
