import * as React from 'react';
import { SVGProps } from 'react';
const SvgVanIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 66 29" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.195}
      d="M19.32 24.245h27.79m9.684 0h4.564c3.046 0 3.046-2.77 3.046-2.77V18.33c0-1.72-.684-2.263-.684-2.263s-1.667-2.152-2.525-2.415c-1.174-.361-10.01-3.067-10.01-3.067-3.515-1.737-8.873-6.241-12.306-7.741-3.432-1.5-7.283-1.287-11.148-1.287-3.862 0-21.344-.025-23.327-.032-2.142-.004-2.883.89-2.861 2.393.02 1.5.003 9.73.003 9.73v4.366c0 .01-.003.021-.003.032v3.302c0 .684.503 1.269 1.184 1.368l.844.124 5.823.809m4.74-19.082 1.976 6.44m3.411 11.759a5.027 5.027 0 0 1-5.028 5.028 5.027 5.027 0 1 1 0-10.056 5.03 5.03 0 0 1 5.028 5.028Zm-2.915 0a2.115 2.115 0 0 1-4.23 0 2.115 2.115 0 0 1 4.23 0Zm40.387 0a5.027 5.027 0 0 1-5.028 5.028 5.027 5.027 0 1 1 0-10.056 5.03 5.03 0 0 1 5.028 5.028Zm-2.915 0a2.115 2.115 0 0 1-4.23 0 2.115 2.115 0 0 1 4.23 0Zm-8.032-11.759c-.776-.521-1.57-1.067-2.393-1.635-2.185-1.514-4.444-3.081-5.986-3.759-2.202-.96-4.848-1.042-7.373-1.042 0 0-2.039.01-2.386.01l1.975 6.426h16.163ZM4.571 4.55c.01 1.454.007 4.326.004 6.457H25.96l-1.979-6.443c-4.191-.004-18.24-.01-19.411-.014Z"
    />
  </svg>
);
export default SvgVanIcon;
