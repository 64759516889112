import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  AppleSignInQuery,
  AppleSignInQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { useTokens } from '../state';

export const APPLE_SIGN_IN = gql(/* GraphQL */ `
  query AppleSignIn($appleIdentityToken: String!) {
    appleLogin(appleIdentityToken: $appleIdentityToken) {
      customer {
        auth {
          firstName
          lastName
          id
          token
          issuedAt
          expiresAt
        }
      }
      refreshToken
    }
  }
`);

export const useAppleSignIn = (
  options?: QueryHookOptions<AppleSignInQuery, AppleSignInQueryVariables>
) => {
  const { setAccessToken, setRefreshToken } = useTokens();

  return useLazyQuery(APPLE_SIGN_IN, {
    ...options,
    onCompleted: (data) => {
      setAccessToken(data.appleLogin.customer.auth.token);
      setRefreshToken(data.appleLogin.refreshToken);
      options?.onCompleted?.(data);
    },
  });
};
