import * as React from 'react';
import { SVGProps } from 'react';
const SvgCreditCardCvcIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      d="M24 4.985V7H0V4.985C0 3.89.896 3 2 3h20c1.104 0 2 .89 2 1.985ZM0 10h24v9.544a1.993 1.993 0 0 1-2 1.985H2c-1.104 0-2-.889-2-1.985V10Zm16.5 1.5a2 2 0 1 0 0 4h4a2 2 0 1 0 0-4h-4Zm-1 2a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCreditCardCvcIcon;
