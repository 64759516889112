import * as React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { createContext } from '@hermes/utils/context';
import { Toast } from './Toast';

export interface ToastType {
  title: string;
  readonly id?: string;
  description?: string;
  variant: 'positive' | 'negative' | 'warning' | 'info';
  duration?: number;
}
interface ToastContext {
  addToast: (params: ToastType) => void;
}

const [useToasts, ToastsProvider] = createContext<ToastContext>();

export interface ToastsProps {
  children: React.ReactNode;
}
const Toasts = (props: ToastsProps) => {
  const [toasts, setToasts] = React.useState<ToastType[]>([]);

  const addToast = React.useCallback(
    (params: ToastType) => {
      setToasts((prev) => [
        ...prev,
        { ...params, id: `tooltip-${Date.now()}-${Math.random()}` },
      ]);
    },
    [setToasts]
  );

  const value = React.useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastPrimitive.Provider>
      <ToastsProvider value={value}>{props.children}</ToastsProvider>
      {toasts.map((toast) => (
        <Toast.Root variant={toast.variant} key={toast.id}>
          <Toast.Close />
          <Toast.Title>{toast.title}</Toast.Title>
          <Toast.Description>{toast.description}</Toast.Description>
        </Toast.Root>
      ))}
      <ToastPrimitive.Viewport className="[--viewport-padding:_25px] fixed bottom-0 right-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
    </ToastPrimitive.Provider>
  );
};

export { Toasts, useToasts };
