import * as React from 'react';
import * as RdxTooltip from '@radix-ui/react-tooltip';
import type { TooltipContentProps as RdxTooltipContentProps } from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';

const sizeClasses = {
  sm: 'max-w-[200px]',
  md: 'max-w-[350px]',
  lg: 'max-w-[450px]',
  xl: 'max-w-[600px]',
} as const;

type Size = keyof typeof sizeClasses;

export interface TooltipContentProps extends RdxTooltipContentProps {
  size?: Size;
}

const Content = React.forwardRef(function ContentInner(
  {
    sideOffset = 5,
    size = 'md',
    side = 'bottom',
    align = 'center',
    children,
    className,
    style,
    ...props
  }: TooltipContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RdxTooltip.Content
      ref={ref}
      className={twMerge(
        'rounded-md bg-primary-dark text-white z-30 shadow-md py-2 px-4 data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade',
        sizeClasses[size],
        className
      )}
      sideOffset={sideOffset}
      side={side}
      align={align}
      {...props}
    >
      {children}
      <RdxTooltip.Arrow className="fill-primary-dark" />
    </RdxTooltip.Content>
  );
});

export const Tooltip = {
  Provider: RdxTooltip.Provider,
  Root: RdxTooltip.Root,
  Trigger: RdxTooltip.Trigger,
  Portal: RdxTooltip.Portal,
  Content,
};
