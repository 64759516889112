import * as React from 'react';
import { SVGProps } from 'react';
const SvgPaypalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M33.031 28C39 28 43 25.5 43 20s-4-8-9.969-8H22l-5 31h9l2-15h5.031Z"
      clipRule="evenodd"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M18 36h-8l5-31h11.031C32 5 36 7.5 36 13s-4 8-9.969 8H21"
    />
  </svg>
);
export default SvgPaypalIcon;
