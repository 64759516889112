import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export const inputClasses = twMerge(
  'group/input px-3 py-1.5 border border-gray-200 rounded-md shadow-sm outline-none transition-border-color duration-300',
  'focus:border-primary focus-within:border-primary focus-within:hover:border-primary  hover:border-gray-300 aria-invalid:bg-red-100/20 aria-invalid:border-red-500'
);

export const inputSizeClasses = {
  sm: 'text-sm px-2 h-6',
  md: 'text-base px-3 py-1.5',
  lg: 'text-lg px-4 py-2',
} as const;

export type InputSize = keyof typeof inputSizeClasses;

export interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'ref' | 'onChange'> {
  size?: InputSize;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size = 'md', ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={twMerge(inputClasses, inputSizeClasses[size], className)}
        {...props}
      />
    );
  }
);
