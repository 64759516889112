import { createContext } from '@hermes/utils/context';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface FieldContext {
  id: string;
  error?: string | undefined;
  name: string;
}

const [useField, FieldContext] = createContext<FieldContext>();

interface RootProps {
  name: string;
  error?: string;
  children: React.ReactNode;
}

const Root = ({ children, name, error }: RootProps) => {
  const id = React.useId();

  return <FieldContext value={{ id, name, error }}>{children}</FieldContext>;
};

const Label = ({
  className,
  ...props
}: Omit<React.HTMLProps<HTMLLabelElement>, 'htmlFor'>) => {
  const { name } = useField();
  return (
    <label
      htmlFor={name}
      className={twMerge('block leading-8 font-medium text-primary', className)}
      {...props}
    />
  );
};

interface ControlProps {
  children: React.ReactNode;
}

const Control = ({ children }: ControlProps) => {
  const { id, name, error } = useField();
  const element = React.Children.only(children) as React.ReactElement;

  return React.cloneElement(element, {
    name,
    id: name,
    'aria-describedby': [
      element.props['aria-describedby'],
      error ? `error-${id}` : '',
    ].join(' '),
    'aria-invalid': Boolean(error) || element.props['aria-invalid'],
  });
};

const ErrorMessage = ({
  className,
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, 'children'>) => {
  const { error, id } = useField();

  if (!error) return null;

  return (
    <div
      id={`error-${id}`}
      className={twMerge('text-froly60 leading-7 text-sm mt-1', className)}
      {...props}
    >
      {error}
    </div>
  );
};

export const Field = {
  Label,
  Control,
  Root: Root,
  ErrorMessage,
};
