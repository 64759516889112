import * as React from 'react';
import { Select } from './Select';
import { Time, parseTime } from '@internationalized/date';
import { createContext } from '@hermes/utils/context';
import { twMerge } from 'tailwind-merge';

const DEFAULT_TIMESPAN = 5;

// eslint-disable-next-line import/no-default-export
export default { title: 'Select' };

export const roundTime = (time: Time, span = DEFAULT_TIMESPAN) => {
  const minuteToRound = time.minute % span;

  if (minuteToRound === 0) {
    return {
      roundedValue: time,
      addedMin: 0,
    };
  }

  const addedMin = span - minuteToRound;

  return {
    roundedValue: time.add({
      minutes: addedMin,
    }),
    addedMin,
  };
};

export const sanitizeTime = (time: Time) => {
  const { roundedValue } = roundTime(time);
  return roundedValue.set({ second: 0, millisecond: 0 });
};

const getTimestamps = (startTime: Time): Time[] => {
  const timestamps: Time[] = [];
  const endTime = startTime.set({ hour: 23, minute: 50 });

  let { roundedValue: currentTime } = roundTime(startTime);

  while (currentTime.compare(endTime) < 0) {
    timestamps.push(currentTime);
    currentTime = currentTime.add({ minutes: DEFAULT_TIMESPAN });
  }

  timestamps.push(currentTime);
  return timestamps;
};

const zonedDateTimeToTimeString = (time: Time) =>
  `${time.hour.toString().padStart(2, '0')}:${time.minute
    .toString()
    .padStart(2, '0')}`;

const isValueValid = (v: Time, options: Time[]) =>
  options.some((option) => option.toString() === v.toString());

interface SelectTimeContext {
  options: Time[];
}

const [useSelectTime, SelectTimeProvider] = createContext<SelectTimeContext>();

interface SelectTimeRootProps {
  start?: Time;
  onValueChange?: (date: Time) => void;
  value?: Time;
  children?: React.ReactNode;
}

const SelectTimeRoot = ({
  value: valueProp,
  start: startProp,
  onValueChange,
  children,
}: SelectTimeRootProps) => {
  const isControlled = valueProp !== undefined;
  const sanitizedStartTime = sanitizeTime(startProp ?? new Time());
  const startTimeString = sanitizedStartTime.toString();

  const options = React.useMemo(() => {
    const _startTime = parseTime(startTimeString);
    return getTimestamps(_startTime);
  }, [startTimeString]);

  const [value, setValue] = React.useState<Time | undefined>(
    valueProp && isValueValid(valueProp, options) ? valueProp : options[0]
  );

  const valuePropString = valueProp?.toString();
  const valueString = value?.toString();

  React.useEffect(() => {
    const _value = valuePropString ? parseTime(valuePropString) : undefined;

    if (isControlled && _value) {
      setValue((v) => {
        const isStateDifferent = valuePropString !== v?.toString();
        return isStateDifferent && isValueValid(_value, options) ? _value : v;
      });
    }
  }, [valuePropString, valueString, isControlled, options]);

  React.useEffect(() => {
    const _startTime = parseTime(startTimeString);
    const _value = valueString ? parseTime(valueString) : undefined;

    if (!_value) {
      setValue(_startTime);
    }

    if (_value && _value.compare(_startTime) < 0) {
      setValue(_startTime);
    }
  }, [startTimeString, valueString]);

  const handleValueChange = (v: string) => {
    setValue(parseTime(v));
    onValueChange?.(parseTime(v));
  };

  return (
    <SelectTimeProvider value={{ options }}>
      <Select.Root
        value={value?.toString()}
        onValueChange={handleValueChange}
        defaultValue={isControlled ? undefined : options[0].toString()}
      >
        {children}
      </Select.Root>
    </SelectTimeProvider>
  );
};

interface SelectTimeTriggerProps {
  className?: string;
  'aria-label'?: string;
  'aria-invalid'?: boolean;
}

const SelectTimeTrigger = React.forwardRef(
  (
    {
      className,
      'aria-invalid': ariaInvalid,
      'aria-label': ariaLabel,
    }: SelectTimeTriggerProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Select.Trigger
        ref={ref}
        aria-invalid={ariaInvalid}
        aria-label={ariaLabel}
        className={twMerge(
          'min-w-none aria-invalid:border-froly60 aria-invalid:group-data-[state=open]:border-froly60 aria-invalid:group-hover:border-froly60`',
          className
        )}
      >
        <Select.Value />
      </Select.Trigger>
    );
  }
);

const SelectTimeContent = () => {
  const { options } = useSelectTime();

  return (
    <Select.Content>
      <Select.Viewport>
        {options.map((option) => (
          <Select.Item key={option.toString()} value={option.toString()}>
            <Select.ItemText>
              {zonedDateTimeToTimeString(option)}
            </Select.ItemText>
          </Select.Item>
        ))}
      </Select.Viewport>
    </Select.Content>
  );
};

export const SelectTime = {
  Root: SelectTimeRoot,
  Trigger: SelectTimeTrigger,
  Content: SelectTimeContent,
};
