import { ApolloLink } from '@apollo/client';

export const paginationLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (!response?.extensions?.pagination) {
      return response;
    }

    if (response?.data) {
      response.data = {
        ...response?.data,
        pagination: {
          id: operation.operationName,
          ...response.extensions?.pagination,
        },
      };
    }

    return response;
  });
});
