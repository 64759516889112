import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ListProps = {
  children: ReactNode;
  className?: string;
};

const ListRoot: React.FC<ListProps> = ({ children, className }) => {
  return (
    <ul className={twMerge('flex flex-col gap-2', className)}>{children}</ul>
  );
};

type ListItemProps = {
  children: ReactNode;
  className?: string;
};

const ListItem: React.FC<ListItemProps> = ({ children, className }) => {
  return <li className={twMerge(className)}>{children}</li>;
};

const ListItemContent: React.FC<ListItemProps> = ({ children, className }) => {
  return (
    <div className={twMerge('flex-1 flex flex-col', className)}>{children}</div>
  );
};

const ListItemAction: React.FC<ListItemProps> = ({ children, className }) => {
  return <div className={twMerge('flex gap-1', className)}>{children}</div>;
};

export const List = {
  Root: ListRoot,
  Item: ListItem,
  ItemContent: ListItemContent,
  ItemAction: ListItemAction,
};
