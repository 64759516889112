import { CreateRideRequestMutation } from '@customer-booking/__generated__/graphql';
import { create } from '@hermes/utils/store';

export type RideRequest = CreateRideRequestMutation['createRideRequestWeb'];

export interface AppStoreState {
  rideRequest?: CreateRideRequestMutation['createRideRequestWeb'];
  hasAnyData: (state: AppStoreState) => boolean;
  setRideRequest: (rideRequest: RideRequest) => void;
  reset: () => void;
}

export const useAppStore = create<AppStoreState>((set) => ({
  rideRequest: undefined,

  setRideRequest: (rideRequest) => set(() => ({ rideRequest })),
  hasAnyData: (state) => !!state.rideRequest,
  reset: () =>
    set(() => ({
      rideRequest: undefined,
    })),
}));
