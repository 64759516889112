import * as React from 'react';
import { useRouter } from 'next/router';

import { createContext } from '@hermes/utils/context';

const [usePreviousRoute, ContextProvider] = createContext<{
  previousRoute: string;
}>();

const PreviousRouteProvider = ({ children }: { children: React.ReactNode }) => {
  const { asPath } = useRouter();

  const ref = React.useRef<string>();

  React.useEffect(() => {
    const ignoreRoutes = [
      '/sign-in',
      '/sign-up',
      '/sign-out',
      '/change-password',
      '/validate-account',
    ];

    if (!ignoreRoutes.some((route) => asPath.includes(route))) {
      ref.current = asPath;
    }
  }, [asPath]);

  return (
    <ContextProvider value={{ previousRoute: ref.current ?? asPath }}>
      {children}
    </ContextProvider>
  );
};

export { PreviousRouteProvider, usePreviousRoute };
