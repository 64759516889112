export const convertWeight = (
  grams: number,

  unit: 'auto' | 'kg' | 'g' | 't' = 'auto'
) => {
  switch (unit) {
    case 'kg':
      return `${Math.round((grams / 1000) * 100) / 100} kg`;

    case 't':
      return `${Math.round((grams / (1000 * 1000)) * 100) / 100} t`;

    case 'g':
      return `${grams} g`;

    default:
      if (grams > 1000 * 1000) {
        return `${Math.round((grams / (1000 * 1000)) * 100) / 100} t`;
      }

      if (grams > 1000) {
        return `${Math.round((grams / 1000) * 100) / 100} kg`;
      }

      return `${grams} g`;
  }
};
