type Listener<T extends object> = (state: T) => void;

export class Store<T extends object> {
  private state: T;
  private listeners: Set<Listener<T>>;

  constructor(initialState: T = {} as T) {
    this.state = initialState;
    this.listeners = new Set();
  }

  setState(newState: T) {
    this.state = newState;
    this.listeners.forEach((listener) => listener(this.state));
  }

  getState() {
    return this.state;
  }

  subscribe(listener: Listener<T>) {
    this.listeners.add(listener);

    return () => {
      const nextListeners = new Set(this.listeners);
      nextListeners.delete(listener);
      this.listeners = nextListeners;
    };
  }
}
