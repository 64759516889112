import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { createContext } from '@hermes/utils/context';
import { CheckCircleIcon } from '@hermes/icons/simple';

export type ToggleButtonGroupVariant =
  | 'primary'
  | 'accent'
  | 'secondary'
  | 'card'
  | 'radio';
export type ToogleButtonGroupSpacing = 1 | 2 | 4 | 6 | 'none';

interface ToggleGroupContext {
  variant: ToggleButtonGroupVariant;
  spacing: ToogleButtonGroupSpacing;
  value?: string;
}

const [useToggleGroup, ToggleGroupProvider] =
  createContext<ToggleGroupContext>();

export interface RootProps
  extends Omit<ToggleGroup.ToggleGroupSingleProps, 'type'> {
  variant?: ToggleButtonGroupVariant;
  spacing?: ToogleButtonGroupSpacing;
  indeterminate?: boolean;
}

export const Root = React.forwardRef(function RootInner(
  {
    className,
    variant = 'primary',
    spacing = 2,
    indeterminate = false,
    ...props
  }: RootProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [stateValue, setStateValue] = React.useState<string>(
    props.defaultValue || ''
  );

  const gap = spacing === 'none' ? 'gap-0' : `gap-${spacing}`;

  const variantClasses = {
    primary: 'rounded-full bg-gray-100',
    accent: 'rounded-full bg-gray-100',
    secondary: `${gap}`,
    card: `flex flex-col ${gap}`,
    radio: `flex flex-col ${gap}`,
  };

  React.useEffect(() => {
    if (props.value !== undefined) {
      setStateValue(props.value);
    }
  }, [props.value]);

  const handleValueChange = (value: string) => {
    if (value !== '' || indeterminate) {
      props.onValueChange?.(value);
      setStateValue(value);
    }
  };

  return (
    <ToggleGroupProvider value={{ variant, value: props.value, spacing }}>
      <ToggleGroup.Root
        {...props}
        value={stateValue}
        onValueChange={handleValueChange}
        ref={ref}
        className={twMerge('inline-flex', variantClasses[variant], className)}
        type="single"
      />
    </ToggleGroupProvider>
  );
});

type ItemProps = ToggleGroup.ToggleGroupItemProps;

export const Item = ({
  textAlignment = 'center',
  className,
  children,
  ...props
}: ItemProps & { textAlignment?: 'center' | 'left' | 'right' }) => {
  const { variant, spacing } = useToggleGroup();
  const nospace = spacing === 'none';
  const stackVariantClasses = `rounded-md border-2 py-6 border-gray-200 hover:border-gray-300 transition-colors duration-200
      data-[state=on]:bg-transparent data-[state=on]:text-primary data-[state=on]:z-30 data-[state=on]:hover:text-primary-dark data-[state=on]:border-accent data-[state=on]:hover:border-accent-dark
      focus-visible:shadow-sm focus-visible:border-primary focus-visible:data-[state=on]:border-accent-dark outline-none cursor-pointer
      ${
        nospace
          ? 'rounded-none -mt-[2px] hover:z-20 last:rounded-b-md first:rounded-t-md'
          : ''
      }`;

  const textAlignmentClasses = {
    center: 'items-center',
    left: 'items-left',
    right: 'items-right',
  }[textAlignment];

  const variantClasses = {
    primary: 'data-[state=on]:bg-primary data-[state=on]:text-white',
    accent: 'data-[state=on]:bg-accent data-[state=on]:text-white',
    secondary:
      'text-primary-dark ring ring-2 ring-inset ring-primary hover:ring-primary-dark hover:text-primary-10 data-[state=on]:bg-primary data-[state=on]:text-white',
    card: stackVariantClasses,
    radio: stackVariantClasses,
  }[variant];

  return (
    <ToggleGroup.Item
      className={twMerge(
        `group flex gap-4 relative rounded-full px-4 py-2 text-primary data-[state=on]:bg-accent data-[state=on]:text-white focus:shadow-sm transition-colors transition-duration-200 focus-ring-visible ${textAlignmentClasses}`,
        variantClasses,
        className
      )}
      {...props}
    >
      {children}
    </ToggleGroup.Item>
  );
};

interface ItemIndicatorProps {
  className?: string;
}

const ItemIndicator = ({ className }: ItemIndicatorProps) => {
  const { variant } = useToggleGroup();

  if (variant === 'card') {
    return (
      <CheckCircleIcon
        aria-hidden="true"
        className={twMerge(
          'absolute left-4 group-data-[state=off]:opacity-0 group-data-[state=on]:opacity-1 size-5 fill-accent transition-opacity ease-in duration-200',
          className
        )}
      />
    );
  }

  if (variant === 'radio') {
    return (
      <div className="rounded-full relative border-2 group-data-[state=on]:border-accent group-data-[state=on]:group-hover:border-accent-dark group-hover:border-gray-300 border-gray-200 w-4 h-4 transition-colors duration-200">
        <div className="group-data-[state=off]:bg-accent/0 group-data-[state=on]:bg-accent group-data-[state=on]:group-hover:bg-accent-dark absolute top-[2px] bottom-[2px] left-[2px] right-[2px] rounded-full transition-colors duration-200" />
      </div>
    );
  }

  return null;
};

interface ItemAnimateProps {
  className?: string;
  children?: React.ReactNode;
}

const ItemAnimate = ({ className, children }: ItemAnimateProps) => {
  return (
    <div
      className={twMerge(
        'translate-y-0 group-data-[state=off]:translate-x-0 group-data-[state=on]:translate-x-7 transition-transform duration-200',
        className
      )}
    >
      {children}
    </div>
  );
};

export const ToggleButtonGroup = { Root, Item, ItemIndicator, ItemAnimate };
