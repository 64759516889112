import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  GoogleSignInQuery,
  GoogleSignInQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { useTokens } from '../state';

export const GOOGLE_SIGN_IN = gql(/* GraphQL */ `
  query GoogleSignIn($idToken: String!, $clientType: ClientPlatform!) {
    googleLogin(idToken: $idToken, clientType: $clientType) {
      customer {
        id @client
        auth {
          firstName
          lastName
          id
          token
          issuedAt
          expiresAt
        }
      }
      refreshToken
    }
  }
`);

export const useGoogleSignIn = (
  options?: QueryHookOptions<GoogleSignInQuery, GoogleSignInQueryVariables>
) => {
  const { setAccessToken, setRefreshToken } = useTokens();

  return useLazyQuery(GOOGLE_SIGN_IN, {
    ...options,
    onCompleted: (data) => {
      setAccessToken(data.googleLogin.customer.auth.token);
      setRefreshToken(data.googleLogin.refreshToken);
      options?.onCompleted?.(data);
    },
  });
};
