import { ApolloLink } from '@apollo/client';
import { AuthContextType } from './shared.types';

export const authHeaderLink = new ApolloLink((operation, forward) => {
  const { accessTokenProvider } = operation.getContext() as AuthContextType;

  if (!accessTokenProvider.getToken()) {
    return forward(operation);
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${accessTokenProvider.getToken()}`,
    },
  }));

  return forward(operation);
});
