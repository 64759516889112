import { MutationHookOptions, useMutation } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '@customer-booking/__generated__/graphql';

export const RESET_PASSWORD = gql(/* GraphQL */ `
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`);

export const useResetPassword = (
  options?: MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) => {
  return useMutation(RESET_PASSWORD, {
    ...options,
  });
};
