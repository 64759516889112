import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface PaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  className?: string;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  className,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const pages: Array<number | '...'> = React.useMemo(
    () =>
      [1, currentPage - 1, currentPage, currentPage + 1, totalPages]
        .filter((page) => page > 0 && page <= totalPages)
        .filter((page, index, self) => self.indexOf(page) === index)
        .reduce((acc, page, index, self) => {
          if (index === 0) {
            return [page];
          }

          const prevPage = self[index - 1];
          if (page - prevPage > 1) {
            return [...acc, '...', page];
          }

          return [...acc, page];
        }, [] as Array<number | '...'>),
    [currentPage, totalPages]
  );

  return (
    <div className={twMerge('flex justify-center gap-2', className)}>
      {pages.map((page, i) => {
        if (page === '...') {
          return <span key={i}>...</span>;
        }

        return (
          <button
            key={i}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 rounded ${
              currentPage === page
                ? 'bg-primary text-white hover:bg-primary-dark'
                : 'bg-gray-200 hover:bg-gray-300 text-primary'
            }`}
            aria-current={currentPage === page ? 'page' : undefined}
            aria-label={`Page ${page}`}
            disabled={currentPage === page}
          >
            {page}
          </button>
        );
      })}
    </div>
  );
};

export default Pagination;
