import Image from 'next/image';

export const MarceLogo = (
  props: Partial<React.ComponentProps<typeof Image>>
) => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
    return (
      <Image
        src="/logo-marcel.webp"
        alt="marcel"
        unoptimized
        {...props}
        priority
      />
    );
  }

  return (
    <Image
      src="/logo-marcel-deva.png"
      alt="marcel"
      unoptimized
      {...props}
      priority
    />
  );
};
