import { useIntl } from 'react-intl';
import { parseLocale } from '../utils';
import { useLocalizePath } from './useLocalizePath';

export const useLocale = () => {
  const { locale } = useIntl();
  const localizePath = useLocalizePath(locale);

  return {
    locale: parseLocale(locale),
    localizePath,
  };
};
