import * as React from 'react';
import * as RdxNavigationMenu from '@radix-ui/react-navigation-menu';
import { twMerge } from 'tailwind-merge';

type RootProps = RdxNavigationMenu.NavigationMenuProps;

const Root = ({ className, ...props }: RootProps) => {
  return (
    <RdxNavigationMenu.Root
      className={twMerge(
        'relative bg-white z-[1] flex w-screen justify-start items-center',
        className
      )}
      {...props}
    />
  );
};

type ListProps = RdxNavigationMenu.NavigationMenuListProps;

const List = ({ className, ...props }: ListProps) => {
  return (
    <RdxNavigationMenu.List
      className={twMerge('m-0 flex list-none p-1', className)}
      {...props}
    />
  );
};

type ItemProps = RdxNavigationMenu.NavigationMenuItemProps;

const Item = ({ className, ...props }: ItemProps) => {
  return <RdxNavigationMenu.Item {...props} />;
};

type LinkProps = RdxNavigationMenu.NavigationMenuLinkProps;

const Link = ({
  className,
  children,
  asChild = false,
  ...props
}: LinkProps) => {
  return (
    <RdxNavigationMenu.Link {...props} asChild={asChild}>
      {children}
    </RdxNavigationMenu.Link>
  );
};

export const Navigation = { Root, List, Item, Link };
