import * as React from 'react';
import { SVGProps } from 'react';
const SvgReturnArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
    />
  </svg>
);
export default SvgReturnArrow;
