import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '../Spinner';

const sizeClasses = {
  xs: 'text-sm px-3 py-1/2',
  sm: 'text-sm px-4 py-1',
  md: 'text-base px-5 py-1',
  lg: 'text-lg px-6 py-1',
};

export type ButtonSize = keyof typeof sizeClasses;

interface ButtonInnerProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'type'> {
  variant?: 'primary' | 'secondary' | 'accent' | 'text';
  size?: ButtonSize;
  children: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
}

const variantClasses = {
  primary:
    'bg-primary text-white hover:bg-primary-dark hover:disabled:bg-primary',
  secondary:
    'text-primary-dark ring ring-2 ring-inset ring-primary hover:ring-primary-dark hover:text-primary-10 hover:disabled:ring-primary',
  accent: 'bg-accent text-white hover:bg-accent-dark hover:disabled:bg-accent',
  text: 'text-accent hover:text-accent-dark hover:disabled:text-accent shadow-none hover:shadow-none px-1 h-auto underline',
};

export const getButtonClassNames = ({
  size = 'md',
  variant = 'primary',
  className,
}: Partial<ButtonInnerProps>) =>
  twMerge(
    `antialiased shadow-sm text-base tracking-wide rounded-full leading-full
         transition-color duration-300 hover:shadow-lg active:shadow-lg focus:shadow-lg focus-ring-visible
         disabled:opacity-30 disabled:cursor-not-allowed disabled:hover:shadow-none`,
    sizeClasses[size],
    variantClasses[variant],
    className
  );

export const Button = React.forwardRef(function ButtonInner(
  {
    children,
    className,
    variant = 'primary',
    size = 'md',
    disabled,
    loading,
    ...props
  }: ButtonInnerProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      ref={ref}
      className={getButtonClassNames({ className, size, variant })}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <>
          <Spinner
            variant={variant === 'text' ? 'accent' : variant}
            size={size}
            text=""
            inverted={variant === 'secondary'}
          />
          <div className="invisible h-0 overflow-hidden">{children}</div>
        </>
      ) : (
        children
      )}
    </button>
  );
});

export type ButtonProps = React.ComponentProps<typeof Button>;
