import * as React from 'react';

const getScrollState = (element: HTMLElement) => {
  const { scrollTop, scrollHeight, offsetHeight } = element;
  const isScrolling = scrollTop > 0;
  const reachTop = scrollTop === 0;
  const reachBottom = scrollTop + offsetHeight === scrollHeight;

  return {
    isScrolling,
    reachTop,
    reachBottom,
  };
};

export const useWatchScroll = (
  element: HTMLElement | null,
  onScrollChange?: (params: {
    isScrolling: boolean;
    reachTop: boolean;
    reachBottom: boolean;
  }) => void
): {
  isScrolling: boolean;
  reachTop: boolean;
  reachBottom: boolean;
} => {
  const [isScrolling, setIsScrolling] = React.useState(false);
  const [reachTop, setReachTop] = React.useState(false);
  const [reachBottom, setReachBottom] = React.useState(false);

  const handleElementSizeChange = React.useCallback(
    (el: HTMLElement) => () => {
      const { isScrolling, reachTop, reachBottom } = getScrollState(el);

      setIsScrolling(isScrolling);
      setReachTop(reachTop);
      setReachBottom(reachBottom);
      onScrollChange?.({ isScrolling, reachTop, reachBottom });
    },
    [onScrollChange, setIsScrolling, setReachTop, setReachBottom]
  );

  React.useEffect(() => {
    if (!element) {
      return;
    }

    const __next = window.document.getElementById('__next') as HTMLElement;
    const observer = new ResizeObserver(handleElementSizeChange(element));

    observer.observe(__next);

    return () => {
      observer.disconnect();
    };
  }, [element, handleElementSizeChange]);

  React.useEffect(() => {
    //mutation observer
    if (!element) {
      return;
    }

    const observer = new MutationObserver(handleElementSizeChange(element));

    observer.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [element, handleElementSizeChange]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (!element) {
        return;
      }

      const { isScrolling, reachTop, reachBottom } = getScrollState(element);

      setIsScrolling(isScrolling);
      setReachTop(reachTop);
      setReachBottom(reachBottom);
      onScrollChange?.({ isScrolling, reachTop, reachBottom });
    };

    element?.addEventListener('scroll', handleScroll);

    return () => {
      element?.removeEventListener('scroll', handleScroll);
    };
  }, [element, onScrollChange]);

  return {
    isScrolling,
    reachTop,
    reachBottom,
  };
};
