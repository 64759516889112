import * as React from 'react';
import * as RdxPopover from '@radix-ui/react-popover';
import type { PopoverContentProps as RdxPopoverContentProps } from '@radix-ui/react-popover';
import { twMerge } from 'tailwind-merge';
import { XMarkIcon } from '@hermes/icons/simple';

const Close = () => {
  return (
    <RdxPopover.Close
      className="rounded-full size-5 inline-flex items-center justify-center text-primary absolute top-[5px] right-[5px] hover:bg-primary/20 outline-none cursor-default transition-colors duration-300 focus:cursor-pointer"
      aria-label="Close"
    >
      <XMarkIcon className="size-4 fill-primary" aria-hidden="true" />
    </RdxPopover.Close>
  );
};

const sizes = {
  sm: 200,
  md: 260,
  lg: 360,
  xl: 420,
  anchor: 'var(--radix-popper-anchor-width)',
};

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'anchor' | number;

export interface PopoverContentProps extends RdxPopoverContentProps {
  arrow?: boolean;
  size?: Size;
  minSize?: Size;
  maxSize?: Size;
}

const getSize = (size: Size | undefined) => {
  return typeof size === 'number'
    ? size
    : size !== undefined
    ? sizes[size]
    : undefined;
};

const Content = React.forwardRef(function ContentInner(
  {
    arrow = true,
    sideOffset = 10,
    size: sizeProp,
    minSize: minSizeProp,
    maxSize: maxSizeProp,
    children,
    className,
    style,
    ...props
  }: PopoverContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const size = getSize(sizeProp);
  const minSize = getSize(minSizeProp);
  const maxSize = getSize(maxSizeProp);

  return (
    <RdxPopover.Content
      ref={ref}
      className={twMerge(
        'rounded-lg bg-white z-30 shadow-md will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade',
        className
      )}
      style={{
        width: size,
        minWidth: minSize,
        maxWidth: maxSize,
        maxHeight: 'calc(var(--radix-popover-content-available-height) - 40px)',
        overflowY: 'auto',
      }}
      sideOffset={sideOffset}
      align="start"
      {...props}
    >
      {arrow && <RdxPopover.Arrow className="fill-white" />}
      {children}
    </RdxPopover.Content>
  );
});

export const Popover = {
  Root: RdxPopover.Root,
  Anchor: RdxPopover.Anchor,
  Trigger: RdxPopover.Trigger,
  Portal: RdxPopover.Portal,
  Content,
  Close,
};
