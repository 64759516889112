import { onError } from '@apollo/client/link/error';
import { AuthContextType } from './shared.types';
import { promiseToObservable } from './helpers';
import { getNewToken } from '../api/refreshToken';
import { NetworkError } from '@apollo/client/errors';

const isUnauthorizedError = (error: NetworkError) => {
  if (error && 'statusCode' in error) {
    return error.statusCode === 401;
  }

  return false;
};

export const onUnauthorizedLink = onError(
  ({ networkError, graphQLErrors, operation, forward }) => {
    const { refreshTokenProvider, cookieTokenProvider, accessTokenProvider } =
      operation.getContext() as AuthContextType;

    const refreshToken = refreshTokenProvider.getToken();

    const hasNetworkUnauthorizedError =
      networkError && isUnauthorizedError(networkError) ? true : false;

    const hasGraphQLUnauthorizedError =
      graphQLErrors?.some(
        (error) => error.extensions?.code === 'UNAUTHORIZED'
      ) ?? false;

    const refresh = async () => {
      const refreshToken = refreshTokenProvider.getToken();
      if (!refreshToken) return undefined;

      const [token, error] = await getNewToken(refreshToken);

      if (error) {
        refreshTokenProvider.clearToken();
        return undefined;
      }

      return token;
    };

    if (
      refreshToken &&
      (hasNetworkUnauthorizedError || hasGraphQLUnauthorizedError)
    ) {
      console.error(
        'Unauthorized error. Trying to refresh token and retry request.',
        {
          hasNetworkUnauthorizedError,
          hasGraphQLUnauthorizedError,
        }
      );

      return promiseToObservable<string | undefined>(refresh()).flatMap(
        (token: unknown) => {
          if (typeof token === 'string') {
            accessTokenProvider.setToken(token);
            cookieTokenProvider.setToken(token);
          }

          return forward(operation);
        }
      );
    }
  }
);
