import React from 'react';

export const useLocalizePath = (locale: string) => {
  const localizePath = React.useCallback(
    (path: string) => {
      return path === '/' ? `/${locale}` : `/${locale}${path}`;
    },
    [locale]
  );

  return localizePath;
};
