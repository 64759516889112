import * as React from 'react';
import { SVGProps } from 'react';
const SvgBerlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 65 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.195}
      d="M18.419 20.702h27.613m9.83 0h4.585c3.042 0 3.042-2.766 3.042-2.766v-3.142c0-1.72-.684-2.262-.684-2.262s-1.667-2.15-2.525-2.415c-1.174-.362-10-3.06-10-3.06S42.096 3.8 37.922 2.112C35.004.897 32.177.71 31.195.684c-1.929-.021-4.393.06-7.025.34h-.003c-1.82.132-6.21.784-8.844 1.827-2.21.872-8.717 4.035-8.717 4.035s-2.418.745-4.195 1.302C.954 8.645.684 9.422.684 10.117v4.358c0 .014-.003.025-.003.035v3.295c0 .688.503 1.27 1.18 1.372l6.82 1.003M44.606 7.294a524.976 524.976 0 0 0-7.648-3.11c-2.578-1.035-4.568-1.248-5.43-1.29a61.673 61.673 0 0 0-4.68.102l1.837 6.316H44.96c.908 0 1.216-1.351-.355-2.018ZM26.401 9.312l-1.784-6.139s-3.447.203-7.11 1.213c-1.684.465-2.688.848-3.255 1.202-.759.514.358 1.309.858 1.749.44.386 1.649 1.585 2.521 1.897.256.05.514.078.766.078h8.004Zm-7.759 9.915a5.025 5.025 0 1 1-10.05-.001 5.025 5.025 0 0 1 10.05 0Zm-2.911 0a2.111 2.111 0 1 1-4.223-.003 2.111 2.111 0 0 1 4.223.003Zm40.35 0a5.025 5.025 0 1 1-10.05-.001 5.025 5.025 0 0 1 10.05 0Zm-2.91 0a2.111 2.111 0 1 1-4.223-.003 2.111 2.111 0 0 1 4.222.003Z"
    />
  </svg>
);
export default SvgBerlineIcon;
