import * as React from 'react';
import { create } from '../store';

type LatLong = [lat: number, long: number];

interface PositionStore {
  position: LatLong | null;
  loading: boolean | null;
  error: GeolocationPositionError | Error | null;
  setPosition: (position: LatLong) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: GeolocationPositionError | Error) => void;
}

const usePositionStore = create<PositionStore>((set) => ({
  position: null,
  loading: null,
  error: null,
  setPosition: (position: LatLong) => set(() => ({ position })),
  setLoading: (loading: boolean) => set(() => ({ loading })),
  setError: (error: GeolocationPositionError | Error) => set(() => ({ error })),
}));

export const isGeoloactionError = (
  error: unknown
): error is GeolocationPositionError => {
  return error instanceof GeolocationPositionError;
};

interface GeolocationUnavailableError extends Error {
  readonly message: 'GEOLOCATION_NOT_SUPPORTED';
}

export const isGeolocationUnavailableError = (
  error: Error
): error is GeolocationUnavailableError => {
  return error.message === 'GEOLOCATION_NOT_SUPPORTED';
};

export const useGetPosition = () => {
  const { position, loading, error, setPosition, setLoading, setError } =
    usePositionStore((state) => state);

  const getPosition = React.useCallback(
    ({
      onSuccess,
      onError,
    }: {
      onSuccess?: (position: LatLong) => void;
      onError?: (error: GeolocationPositionError | Error) => void;
    } = {}) => {
      if ('geolocation' in navigator) {
        if (position) {
          onSuccess?.(position);
          setLoading(false);
          return;
        }

        setLoading(true);

        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latLong: LatLong = [
              position.coords.latitude,
              position.coords.longitude,
            ];

            onSuccess?.(latLong);
            setPosition(latLong);
            setLoading(false);
          },
          (error) => {
            onError?.(error);
            setError(error);
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
        const err = new Error('GEOLOCATION_NOT_SUPPORTED');
        onError?.(err);
        setError(err);
        /* geolocation IS NOT available */
      }
    },
    []
  );

  return { position, getPosition, error, loading, enabled: !!position };
};
