import { create } from '@hermes/utils/store';
import { MapRideStop } from '../types';

export interface MapStoreState {
  dropOff: MapRideStop | undefined;
  pickUp: MapRideStop | undefined;
  stops: MapRideStop[];
}

interface Setters {
  setDropOff: (dropOff: MapRideStop | null) => void;
  setPickUp: (pickUp: MapRideStop | null) => void;
  setStops: (stops: MapRideStop[]) => void;
  set: (state: Partial<MapStoreState>) => void;
  reset: () => void;
  addStop: (stop: MapRideStop, index: number) => void;
  removeStop: (index: number) => void;
  hasAnyData: (state: MapStoreState) => boolean;
}

const initialState = {
  dropOff: undefined,
  pickUp: undefined,
  stops: [],
};

export const useMapStore = create<MapStoreState & Setters>(
  (set) => ({
    ...initialState,
    setDropOff: (dropOff) =>
      set((state) => ({
        ...state,
        dropOff: dropOff || undefined,
      })),
    setPickUp: (pickUp) =>
      set((state) => ({
        ...state,
        pickUp: pickUp || undefined,
      })),
    setStops: (stops) =>
      set((state) => ({
        ...state,
        stops,
      })),
    addStop: (stop, index) => {
      set((state) => {
        const stops = [...state.stops];
        stops.splice(index, 0, stop);
        return {
          ...state,
          stops,
        };
      });
    },
    removeStop: (index) => {
      set((state) => {
        const stops = [...state.stops];
        stops.splice(index, 1);
        return {
          ...state,
          stops,
        };
      });
    },
    set: (nextState) =>
      set((prevState) => ({
        ...prevState,
        ...nextState,
      })),
    hasAnyData: (state) => {
      return (
        state.dropOff !== undefined ||
        state.pickUp !== undefined ||
        state.stops.length > 0
      );
    },
    reset: () => {
      set(() => initialState);
    },
  })
  // debug
  // true
);
