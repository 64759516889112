import { Provider as RollbarProvider } from '@rollbar/react';

import * as React from 'react';

export interface ErrorMonitoringProviderProps {
  children: React.ReactNode;
  config:
    | {
        accessToken: string;
        environment: string;
        codeVersion: string;
      }
    | undefined;
}

export const ErrorMonitoringProvider = ({
  children,
  config: configuration,
}: ErrorMonitoringProviderProps) => {
  if (!configuration) {
    return children;
  }

  const config = {
    captureUnhandledRejections: true,
    captureUncaught: true,
    accessToken: configuration.accessToken,
    environment: configuration.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: configuration.codeVersion,
        guess_uncaught_frames: true,
      },
    },
  };

  return <RollbarProvider config={config}>{children}</RollbarProvider>;
};
