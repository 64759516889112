import * as React from 'react';
import { SVGProps } from 'react';
const SvgCreditCardFrontIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      d="M24 3.985v14.56a1.993 1.993 0 0 1-2 1.984H2c-1.104 0-2-.889-2-1.985V3.985C0 2.89.896 2 2 2h20c1.104 0 2 .89 2 1.985ZM2 16.677a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm7-1a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H9Zm-7-2a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm17-9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCreditCardFrontIcon;
