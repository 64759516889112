import { twMerge } from 'tailwind-merge';

export const FakeInput = ({
  children,
  className,
  hasFocus,
}: {
  children?: React.ReactNode;
  className?: string;
  hasFocus?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'box-border flex justify-center items-center border-2 rounded-lg transition-all duration-300 font-text font-bold text-primary text-lg m-1 md:m-2',
        hasFocus
          ? 'border-primary'
          : 'border-gray-300 hover:border-primary hover:cursor-pointer',
        className
      )}
    >
      {children}
      {hasFocus && (
        <div className="w-[2px] rounded h-[50%] bg-primary animate-blinkCarret" />
      )}
    </div>
  );
};
