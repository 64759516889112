import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { EyeIcon, EyeSlashIcon } from '@hermes/icons/simple';

const inputClasses = twMerge(
  'inline-flex gap-2 items-center px-3 py-1.5 border border-gray-200 rounded-md shadow-sm outline-none focus-within:border-primary hover:focus-within:border-primary transition-border-color duration-300',
  'hover:border-gray-300'
);

const inputSizeClasses = {
  sm: 'text-sm px-2 h-6',
  md: 'text-base px-3 py-1.5',
  lg: 'text-lg px-4 py-2',
} as const;

type InputSize = keyof typeof inputSizeClasses;

export interface PasswordInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'ref' | 'onChange'> {
  size?: InputSize;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(({ className, size = 'md', 'aria-invalid': ariaInvalid, ...props }, ref) => {
  const [type, setType] = React.useState<'password' | 'text'>('password');

  const toggleType = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <div
      className={twMerge(
        inputClasses,
        inputSizeClasses[size],
        ariaInvalid && 'bg-red-100/20 border-red-500',
        className
      )}
    >
      <input
        ref={ref}
        className="outline-none w-full"
        placeholder={'*'.repeat(8)}
        type={type}
        aria-invalid={ariaInvalid}
        {...props}
      />
      <button type="button" onClick={toggleType}>
        {type === 'password' ? (
          <EyeSlashIcon className="size-4 stroke-primary fill-transparent" />
        ) : (
          <EyeIcon className="size-4 stroke-primary fill-transparent" />
        )}
      </button>
    </div>
  );
});
