import * as React from 'react';
import Image from 'next/image';
import Script from 'next/script';
import { FormattedMessage } from 'react-intl';

import { useLocale } from '@customer-booking/features/l10n';
import { Button } from '@hermes/ui';
import facebookLogo from './facebook.png';

export type FacebookSignInSuccessHandler = (
  facebookAccessToken: string,
  user: {
    firstName: string;
    lastName: string;
    email: string;
  }
) => void;

const getLang = (locale: string) => {
  switch (locale) {
    case 'en':
      return 'en_US';
    case 'fr':
    default:
      return 'fr_FR';
  }
};

export interface FacebookSignInProps {
  onSignInSuccess: FacebookSignInSuccessHandler;
  type?: 'sign-in' | 'continue' | 'sign-up';
}

export const FacebookSignIn = ({
  onSignInSuccess,
  type,
}: FacebookSignInProps) => {
  const id = React.useId();
  const { locale } = useLocale();

  const handleInitFacebookID = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      });
    };
  };

  const handleFacebookLogin = async () => {
    FB.login(
      async (response) => {
        if (response.authResponse) {
          const facebookAccessToken = response.authResponse.accessToken;
          FB.api(
            '/me',
            { fields: 'first_name,last_name,email' },
            (user: {
              first_name: string;
              last_name: string;
              email: string;
            }) => {
              onSignInSuccess(facebookAccessToken, {
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
              });
            }
          );
        } else {
          console.error('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'email' }
    );
  };

  return (
    <div className="flex items-center justify-center">
      <div id="fb-root"></div>
      <Script
        // use key to force mount-unmount on locale change
        key={locale}
        src={`https://connect.facebook.net/${getLang(
          locale
        )}/sdk.js#xfbml=1&version=v17.0`}
        onLoad={handleInitFacebookID}
        nonce={id}
      />

      <Button
        className="flex gap-1 h-[40px] justify-center items-center bg-[#1877f2] hover:bg-[#0e68dd] w-full color-white rounded-full"
        onClick={handleFacebookLogin}
        type="button"
      >
        <Image src={facebookLogo} alt="facebook logo" height={24} />
        {type === 'continue' && (
          <FormattedMessage
            description="Continue with Facebook button"
            defaultMessage="Continuer avec Facebook"
          />
        )}
        {type === 'sign-up' && (
          <FormattedMessage
            description="Sign up with Facebook button"
            defaultMessage="S'inscrire avec Facebook"
          />
        )}
        {type === 'sign-in' && (
          <FormattedMessage
            description="Sign in with Facebook button"
            defaultMessage="Connexion avec Facebook"
          />
        )}
      </Button>
    </div>
  );
};
