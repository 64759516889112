import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  FacebookSignInQuery,
  FacebookSignInQueryVariables,
} from '@customer-booking/__generated__/graphql';
import { useTokens } from '../state';

export const FACEBOOK_SIGN_IN = gql(/* GraphQL */ `
  query FacebookSignIn($accessToken: String!) {
    facebookLogin(accessToken: $accessToken) {
      customer {
        id @client
        auth {
          firstName
          lastName
          id
          token
          issuedAt
          expiresAt
        }
      }
      refreshToken
    }
  }
`);

export const useFacebookSignIn = (
  options?: QueryHookOptions<FacebookSignInQuery, FacebookSignInQueryVariables>
) => {
  const { setAccessToken, setRefreshToken } = useTokens();

  return useLazyQuery(FACEBOOK_SIGN_IN, {
    ...options,
    onCompleted: (data) => {
      setAccessToken(data.facebookLogin.customer.auth.token);
      setRefreshToken(data.facebookLogin.refreshToken);
      options?.onCompleted?.(data);
    },
  });
};
