import { MutationHookOptions, useMutation } from '@apollo/client';
import { gql } from '@customer-booking/__generated__';
import {
  ConfirmPhoneValidationCodeMutation,
  ConfirmPhoneValidationCodeMutationVariables,
} from '@customer-booking/__generated__/graphql';
import { FETCH_CUSTOMER_PROFILE } from './fetchProfile';

export const CONFIRM_PHONE_VALIDATION_CODE = gql(/* GraphQL */ `
  mutation ConfirmPhoneValidationCode($code: String!) {
    confirmPhoneValidationCode(code: $code)
  }
`);

export const useConfirmPhoneValidationCode = (
  options?: MutationHookOptions<
    ConfirmPhoneValidationCodeMutation,
    ConfirmPhoneValidationCodeMutationVariables
  >
) => {
  return useMutation(CONFIRM_PHONE_VALIDATION_CODE, {
    ...options,
    refetchQueries: [FETCH_CUSTOMER_PROFILE],
    awaitRefetchQueries: true,
  });
};
