import * as React from 'react';

export const createContext = <T>() => {
  const context = React.createContext<T | undefined>(undefined);

  const useContext = () => {
    const ctx = React.useContext(context);
    if (ctx === undefined) {
      throw new Error('useContext must be used within a Provider');
    }

    return ctx;
  };

  return [useContext, context.Provider] as const;
};
