const ENV = {
  APPLE_CLIENT_ID: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
  APPLE_REDIRECT_URI: process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI,
  COOKIE_DOMAIN: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
  GOOGLE_CAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_SITE_KEY,
  GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  GRAPHQL_API_URL: process.env.NEXT_PUBLIC_GRAPHQL_API,
  MAPBOX_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_TOKEN,
  MARCEL_CAB_ROOT_URL: process.env.NEXT_PUBLIC_MARCEL_CAB_ROOT_URL,
  ROLLBAR_ACCESS_TOKEN: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
  RUM_APPLICATION_ID: process.env.NEXT_PUBLIC_RUM_APPLICATION_ID,
  VERSION: process.env.NEXT_PUBLIC_VERSION,
  RUM_IDENDITY_POOL_ID: process.env.NEXT_PUBLIC_RUM_IDENDITY_POOL_ID,
  IDENTITY_POOL_ID: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  RUM_ENDPOINT: process.env.NEXT_PUBLIC_RUM_ENDPOINT,
  GUEST_ROLE_ARN: process.env.NEXT_PUBLIC_GUEST_ROLE_ARN,
  APPLICATION_REGION: process.env.NEXT_PUBLIC_APPLICATION_REGION,
  RUM_SESSION_SAMPLE_RATE: process.env.NEXT_PUBLIC_RUM_SESSION_SAMPLE_RATE,
  GRAPHQL_API: process.env.NEXT_PUBLIC_GRAPHQL_API,
  GATEWAY_URL: process.env.NEXT_PUBLIC_GRAPHQL_API?.replace('/graph', ''),
} as const;

type Keys = keyof typeof ENV;

export const env = (key: Keys) => {
  const value = ENV[key];
  if (!value) {
    throw new Error(`Missing environment variable: ${key}`);
  }

  return value;
};

export const looseEnv = (key: Keys) => {
  return ENV[key];
};
