import React from 'react';
import { twMerge } from 'tailwind-merge';

export const textareaClasses = twMerge(
  'px-3 py-1.5 border border-gray-200 rounded-md shadow-sm outline-none focus:border-primary transition-border-color duration-300',
  'hover:border-gray-300 aria-invalid:bg-red-100/20 aria-invalid:border-red-500'
);

const sizeClasses = {
  sm: 'text-sm px-2 h-6',
  md: 'text-base px-3 py-1.5',
  lg: 'text-lg px-4 py-2',
} as const;

type Size = keyof typeof sizeClasses;

export interface TextAreaProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'size' | 'ref'
  > {
  size?: Size;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, size = 'md', ...props }, ref) => {
    const sizeClass = size ? sizeClasses[size] : '';

    return (
      <textarea
        ref={ref}
        className={twMerge(textareaClasses, sizeClass, className)}
        {...props}
      />
    );
  }
);
