import * as React from 'react';
import * as RdxSelect from '@radix-ui/react-select';
import { twMerge } from 'tailwind-merge';
import { ChevronDownIcon, ChevronUpIcon } from '@hermes/icons/simple';

const sizeClasses = {
  sm: 'text-base px-3 py-1',
  md: 'text-base px-4 py-1.5',
  lg: 'text-lg px-5 py-2',
} as const;

export interface TriggerProps extends RdxSelect.SelectTriggerProps {
  size?: keyof typeof sizeClasses;
}

const Trigger = React.forwardRef(
  (
    { children, className, size = 'md', id, ...props }: TriggerProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <RdxSelect.Trigger
        ref={ref}
        id={id}
        className={twMerge(
          `antialiased px-6 flex gap-2 items-center shadow-sm text-base tracking-wide rounded-md leading-[100%] min-w-[80px]
         transition-color duration-300 focus-ring-visible border border-gray-200
         disabled:opacity-50 disabled:cursor-not-allowed`,
          sizeClasses[size],
          className
        )}
        {...props}
      >
        {children}
        <RdxSelect.Icon className="fill-primary stroke-primary size-4 ml-auto">
          <ChevronDownIcon />
        </RdxSelect.Icon>
      </RdxSelect.Trigger>
    );
  }
);

const Content = React.forwardRef(function ContentInner(
  { className, children, ...props }: RdxSelect.SelectContentProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RdxSelect.Portal>
      <RdxSelect.Content
        {...props}
        ref={ref}
        className={twMerge(
          'overflow-hidden bg-white rounded-md shadow-md z-30',
          props.position === 'popper'
            ? 'w-[var(--radix-select-trigger-width)] max-h-[var(--radix-select-content-available-height)]'
            : '',
          className
        )}
      >
        <RdxSelect.ScrollUpButton className="flex items-center justify-center w-full">
          <ChevronUpIcon className="size-5 fill-primary" />
        </RdxSelect.ScrollUpButton>
        {children}
        <RdxSelect.ScrollDownButton className="flex items-center justify-center w-full">
          <ChevronDownIcon className="size-5 fill-primary" />
        </RdxSelect.ScrollDownButton>
      </RdxSelect.Content>
    </RdxSelect.Portal>
  );
});

const Item = React.forwardRef(function ItemInner(
  { className, children, ...props }: RdxSelect.SelectItemProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RdxSelect.Item
      {...props}
      ref={ref}
      className={twMerge(
        'px-6 py-2 w-full flex items-center justify-start text-base leading-6 text-primary hover:bg-primary/10 outline-none rounded-sm cursor-pointer data-[highlighted]:bg-primary/10 select-none',
        className
      )}
    >
      {children}
    </RdxSelect.Item>
  );
});

export type SelectProps = RdxSelect.SelectProps;

export const Select = {
  Root: RdxSelect.Root,
  ItemText: RdxSelect.ItemText,
  Value: RdxSelect.Value,
  Trigger,
  Viewport: RdxSelect.Viewport,
  Content,
  Item,
};
