import * as React from 'react';
import { SVGProps } from 'react';
const SvgHandIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 211 151"
    {...props}
  >
    <path
      fill="#F7D6B7"
      d="M136.797 11.12c-6.026 11.354-56.677 37.698-65.62 47.338C62.234 68.1 55.615 77.646 39.37 82.36l-20.432 5.933 13.979 48.182 30.859-8.958c4.64-1.344 21.828-1.542 42.875-1.417 30.318-.333 31.458-3.094 53.672-6.667 8.359-1.343-4.182-14.338-4.573-29.5-.292-11.468 43.885-38.078 32.859-37.224-21.218 1.646-67.317 8.74-68 7.365-4.442-9.537 5.761-13.323 8.875-15.13 22.651-13.136 13.365-36.818 7.313-33.823Z"
    />
    <path
      fill="#EFEFEF"
      d="M46.849 138.344 28.005 78.682 53 70.782l18.844 59.666-24.995 7.896Z"
    />
    <path
      fill="#1D5E7C"
      d="M21.531 150.776.141 83.042l32.63-10.308 21.39 67.735-32.63 10.307Z"
    />
    <path
      fill="#F5D4B5"
      d="M170.682 72.307c6.599-2.192 26.058-4.49 31.766-5.948 14.427-3.687 7.948-13.671-3.479-14.015-8.25-.245-11.646.61-16.558 1.12-7.989 1.505-19.583 20.692-11.729 18.843Z"
    />
    <path
      fill="#F7D6B7"
      d="M160.323 119.432c-6.479-1.567-11.557-2.411-13.938-3.255-10.838-3.854-4.724-13.13.021-13.505 3.391-.271 9.891.375 17.365 1.375 5.755 3.427 1.041 16.474-3.448 15.385ZM130.74 83.922c-12.115-1.927-8.021-15.797 8.036-15.589 10.401.141 21.297-.317 29.313 1.401.385.079.744.136 1.098.188 8.063 1.135 7.803 18.385-3.484 17.906-12.375-2.11-30.302-3.166-34.963-3.906Z"
    />
    <path
      fill="#F7D6B7"
      d="M135.401 84.667c6.182.458 20.958 1.76 28.891 2.682 7.937.922 6.828 16.698-.521 16.698-7.865-1.271-18.979-1.901-30.719-4.589-16.073-3.682-8.422-15.588 2.349-14.791Z"
    />
    <path
      stroke="#F594A6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={0.945}
      d="M152.401 117.661c-2.656-.572-4.729-1.026-6.016-1.484-10.838-3.859-4.724-13.13.021-13.505 2.729-.219 7.474.156 13.12.833M162.219 86.906c-12.141-1.52-27.532-2.354-31.479-2.984-12.115-1.927-8.016-15.797 8.036-15.589 10.401.141 18.49.922 29.313 1.396"
    />
    <path
      stroke="#F594A6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={0.945}
      d="M158.099 103.271c-7.141-.875-15.901-1.719-25.047-3.813-16.068-3.682-8.422-15.593 2.349-14.796 6.188.463 18.885 1.322 26.818 2.244"
    />
    <path
      stroke="#EC6A6A"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={8}
      d="m169.13 22.02-4.682-15.525M184.88 16.875l4.224-12.87M194.604 30.245l11.787-10.39"
    />
  </svg>
);
export default SvgHandIcon;
