import { Hsluv } from 'hsluv';
import { hsla } from 'polished';

const hashCode = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return hash;
};

const toHex = (hash: number): string => {
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
};

export const generateColor = (
  str: string,
  {
    s = 0.8,
    l = 0.94,
  }: {
    s?: number;
    l?: number;
  } = {}
): string => {
  const converter = new Hsluv();
  converter.hex = `#${toHex(hashCode(str))}`;
  converter.hexToHsluv();

  return hsla(converter.hsluv_h, s, l, 1);
};
