import { useApolloClient } from '@apollo/client';
import { useTokens } from '../state';
import { useAppStore } from '@customer-booking/features/store';
import { useMapStore } from '@customer-booking/features/map';

interface Options {
  onCompleted?: () => void;
}

export const useSignOut = () => {
  const resetAppStore = useAppStore((state) => state.reset);
  const resetMapStore = useMapStore((state) => state.reset);

  const { clearAll } = useTokens();
  const client = useApolloClient();

  const signOut = async (options?: Options) => {
    clearAll();

    resetAppStore();
    resetMapStore();
    client.resetStore();
    options?.onCompleted?.();
    window.location.href = `${
      process.env.NEXT_PUBLIC_MARCEL_CAB_ROOT_URL
    }/callback-sign-out?callback_url=${
      window.location.protocol + '//' + window.location.host
    }`;
  };

  return signOut;
};
