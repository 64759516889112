import React from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import Image from 'next/image';
import { twJoin } from 'tailwind-merge';

import { ChevronRightIcon } from '@hermes/icons/simple';
import { Link } from '@customer-booking/components/Link';
import { useLocale } from '@customer-booking/features/l10n';

const initialSlides = (formatMessage: IntlFormatters['formatMessage']) => {
  return [
    {
      id: 'prestige',
      variant: 'prestige',
      img: '/slide-prestige.webp',
      alt: 'prestige slider image',
      text: formatMessage({
        description: 'prestige slider button text',
        defaultMessage: 'Découvrez notre gamme Prestige.',
      }),
      buttonText: formatMessage({
        description: 'prestige slider button text',
        defaultMessage: 'En savoir plus',
      }),
      url: 'https://www.marcel.cab/chauffeur-prive-prestige/?utm_source=Homebanner&utm_medium=New_front&utm_campaign=2024_homebaner_slide_new_front_marcel_prestige',
    },
    {
      id: 'airport',
      variant: 'default',
      img: '/slide-airport.webp',
      alt: 'airport slider image',
      text: formatMessage({
        description: 'airports slider button text',
        defaultMessage:
          'Profitez de nos forfaits aéroports Paris Intra-muros !',
      }),
      buttonText: formatMessage({
        description: 'airports slider button text',
        defaultMessage: "J'en profite",
      }),
      url: 'https://www.marcel.cab/passagers/trajets-aeroports/?utm_source=Homebanner&utm_medium=New_front&utm_campaign=2024_homebaner_slide_new_front_forfaits_aeroports',
    },
    {
      id: 'flexibility',
      variant: 'default',
      img: '/slide-flexibility.webp',
      alt: 'custom ride slider image',
      text: formatMessage({
        description: 'custom rides slider button text',
        defaultMessage:
          "Disposez d'un véhicule avec chauffeur privé pendant plusieurs heures",
      }),
      buttonText: formatMessage({
        description: 'custom ride slider button text',
        defaultMessage: 'En savoir plus',
      }),
      url: 'https://www.marcel.cab/passagers/prestation-sur-mesure/?utm_source=Homebanner&utm_medium=New_front&utm_campaign=2024_homebaner_slide_new_front_prestation_sur_mesure',
    },
    {
      id: 'availability',
      variant: 'default',
      img: '/slide-availability.webp',
      alt: 'availability slider image',
      text: formatMessage({
        description: 'availability slider button text',
        defaultMessage: 'Marcel est disponible dans 65 villes de France',
      }),
      buttonText: formatMessage({
        description: 'availability slider button text',
        defaultMessage: 'En savoir plus',
      }),
      url: 'https://www.marcel.cab/reservez-votre-vtc-marcel-dans-65-villes-en-france/?utm_source=Homebanner&utm_medium=New_front&utm_campaign=2024_homebaner_slide_new_front_65_villes',
    },
    {
      id: 'company',
      variant: 'default',
      img: '/slide-company.webp',
      alt: 'company slider image flexibility',
      text: formatMessage({
        description: 'availability slider button text',
        defaultMessage:
          "Bénéficiez d'une flexibilité maximale pour votre entreprise",
      }),
      buttonText: formatMessage({
        description: 'company slider button text',
        defaultMessage: 'Je découvre',
      }),
      url: 'https://www.marcel.cab/entreprises-decouvrez-loffre-marcel-pro/?utm_source=Homebanner&utm_medium=New_front&utm_campaign=2023_homebaner_slide_new_front_marcel_pro',
    },
  ];
};

export const MarketingCarousel = () => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const _initialSlides = React.useMemo(
    () => initialSlides(formatMessage),
    [formatMessage]
  );

  const [carousel, setCarousel] = React.useState({
    slides: _initialSlides,
    translate: '',
    currentSlideIndex: 0,
  });

  const nextSlide = React.useCallback(() => {
    const nextCarousel = [
      { ...carousel.slides[0], id: 'duplicate' },
      ...carousel.slides.slice(1),
      carousel.slides[0],
    ];

    const nextSlides = nextCarousel.slice(1);
    const nextSlideIndex = carousel.currentSlideIndex + 1;

    // add the first slide to the end
    setCarousel({
      slides: nextCarousel,
      translate: '-translate-x-full transition-all',
      currentSlideIndex: _initialSlides[nextSlideIndex] ? nextSlideIndex : 0,
    });

    // remove the first slide after the transition
    setTimeout(() => {
      setCarousel({
        ...carousel,
        slides: nextSlides,
        translate: 'translate-x-0 transition-none',
        currentSlideIndex: _initialSlides[nextSlideIndex] ? nextSlideIndex : 0,
      });
    }, 600);
  }, [carousel, _initialSlides]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  const handleCarouselNav = (id: string) => {
    if (id === _initialSlides[carousel.currentSlideIndex].id) {
      return;
    }

    const nextSlideIndex = carousel.slides.findIndex(
      (slide) => slide.id === id
    );

    // reorder the slides
    const nextSlides = [
      ...carousel.slides.slice(nextSlideIndex),
      ...carousel.slides.slice(0, nextSlideIndex),
    ];

    setCarousel({
      slides: nextSlides,
      translate: 'translate-x-0 transition-none',
      currentSlideIndex: _initialSlides.findIndex((slide) => slide.id === id),
    });
  };

  React.useEffect(() => {
    // force re-render when locale changes to get accurate translations
    if (locale) {
      setCarousel({
        slides: initialSlides(formatMessage),
        translate: 'translate-x-0 transition-none',
        currentSlideIndex: 0,
      });
    }
  }, [locale, formatMessage]);

  const activeSlide = _initialSlides[carousel.currentSlideIndex];

  return (
    <div className="w-full h-full relative overflow-hidden">
      <div
        className={twJoin(
          'flex items-center h-full justify-start duration-500',
          carousel.translate
        )}
      >
        {carousel.slides.map((slide) => {
          const isPrestige = slide.variant === 'prestige';

          return (
            <div
              key={slide.id}
              className={twJoin(
                `w-full h-full min-w-full`,
                isPrestige ? 'bg-[#F5F6F6]' : 'bg-transparent'
              )}
            >
              <div className="h-full">
                <div className="w-full h-2/3 relative">
                  <Image
                    src={slide.img}
                    alt={slide.alt}
                    className="object-cover"
                    unoptimized
                    priority
                    fill
                  />
                </div>
                <div className="pt-10 flex-col flex items-center h-1/3 w-full">
                  <div className="h-full">
                    <p
                      className={twJoin(
                        'my-4 text-center flex grow h-1/3 text-3xl max-w-md items-center',
                        isPrestige
                          ? 'text-primary-prestige-dark'
                          : 'text-primary'
                      )}
                    >
                      {slide.text}
                    </p>
                    <Link
                      external
                      href={slide.url}
                      variant={isPrestige ? 'prestige' : 'accent'}
                      className="no-underline flex grow h-1/3 justify-center items-center text-lg"
                    >
                      <ChevronRightIcon
                        className={twJoin(
                          'rounded-full size-5 p-[2px] mr-2  stroke-white fill-transparent',
                          isPrestige ? 'bg-accent-prestige' : 'bg-accent'
                        )}
                      />
                      {slide.buttonText}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 py-4">
        {_initialSlides.map((slide) => {
          return (
            <button
              aria-label="carousel nav button"
              key={slide.id}
              className={`w-3 h-3 rounded-full ${
                slide.id === activeSlide.id
                  ? 'bg-primary-dark'
                  : 'bg-dusty_gray'
              }`}
              onClick={() => handleCarouselNav(slide.id)}
            />
          );
        })}
      </div>
    </div>
  );
};
