import * as React from 'react';
import {
  DateFieldState,
  DateSegment as IDateSegment,
} from '@react-stately/datepicker';
import { useDateSegment } from '@react-aria/datepicker';
import { twMerge } from 'tailwind-merge';

interface DateSegmentProps {
  segment: IDateSegment;
  state: DateFieldState;
}

export function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth:
          segment.maxValue != null
            ? String(segment.maxValue).length + 'ch'
            : undefined,
      }}
      className={`px-1 py-0.5 box-content tabular-nums text-right outline-none rounded-sm focus:bg-primary focus:text-white group ${
        !segment.isEditable ? 'text-gray-500' : 'text-primary'
      }`}
    >
      <span
        aria-hidden="true"
        className={twMerge(
          'block w-full text-center italic text-gray-500 group-focus:text-white pointer-events-none',
          segment.isPlaceholder ? 'visible' : 'hidden h-0'
        )}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
}
