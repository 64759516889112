import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { FakeInput } from './FakeInput';

const NUMBER_OF_DIGITS = 6;
const isComplete = (value: string) => value.length === NUMBER_OF_DIGITS;

interface OtpCredentialRequestOptions extends CredentialRequestOptions {
  otp: {
    transport: string[];
  };
}

interface OtpCredential extends Credential {
  code: string;
}

export const InputOTPCode = ({
  isCodeInvalid,
  onCodeComplete,
}: {
  isCodeInvalid?: boolean;
  onCodeComplete?: (code: string) => void;
}) => {
  const [code, setCode] = useState<string>('');
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = () => setHasFocus(true);
  const handleBlur = () => setHasFocus(false);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowLeft', 'ArrowRight'].includes(e.key)) e.preventDefault();
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, NUMBER_OF_DIGITS).trim();
    if (isNaN(Number(value))) return;
    if (value === code) return; // Prevent a lot of `isComplete` calls if someone spam a number key
    setCode(value);
    if (isComplete(value)) onCodeComplete?.(value);
  };

  useEffect(() => {
    if (isCodeInvalid) setCode('');
  }, [setCode, isCodeInvalid]);

  // Catch OTPs received by SMS on android Chrome, ask the user to auto fill it and submit
  useEffect(() => {
    if ('OTPCredential' in window) {
      const abortController = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: abortController.signal,
        } as OtpCredentialRequestOptions)
        .then((otp) => {
          if (otp) {
            const code = (otp as OtpCredential).code;
            setCode(code);
            if (isComplete(code)) onCodeComplete?.(code);
          }
        })
        // gracefull error handling if browser doesn't support OTPCredential
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
      return () => abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-center">
      <div className="relative flex justify-between">
        <input
          className="absolute opacity-0 w-full h-full m-0 p-0 border-none"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{6}"
          value={code}
          onChange={handleChangeCode}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          // aria-label={}
          aria-describedby="error-message"
        />
        {[...Array(NUMBER_OF_DIGITS)].map((d, n) => (
          <FakeInput
            key={n}
            hasFocus={hasFocus && n === code.length}
            className={twMerge(
              `w-[40px] h-[40px] md:w-[50px] md:h-[50px]`,
              isCodeInvalid && 'animate-shake'
            )}
          >
            {code.charAt(n)}
          </FakeInput>
        ))}
      </div>
    </div>
  );
};
