import TagManager from 'react-gtm-module';
import { Analytics } from './analytics.abstract';

export class GoogleAnalytics implements Analytics {
  private gtmId: string | undefined;
  private initialized = false;

  constructor(gtmId: string | undefined) {
    this.gtmId = gtmId;
    this.initialize(gtmId);
  }

  initialize(gtmId: string | undefined) {
    if (!gtmId || typeof window === 'undefined') {
      return;
    }

    TagManager.initialize({
      gtmId,
    });

    this.initialized = true;
  }

  trackEvent(event: string | undefined, data: Record<string, unknown>) {
    if (!this.gtmId || typeof window === 'undefined') {
      return;
    }

    if (!this.initialized) {
      this.initialize(this.gtmId);
    }

    TagManager.dataLayer({
      dataLayer: {
        event,
        ...data,
      },
    });
  }
}
