import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, ButtonProps } from './Button';

export interface IconButtonProps extends ButtonProps {
  children: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  size?: 'sm' | 'md' | 'lg' | 'xs';
  className?: string;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, className, size = 'md', ...props }: IconButtonProps, ref) => {
    const buttonSizeClasses = {
      xs: 'size-[16px]',
      sm: 'size-[20px]',
      md: 'size-[28px]',
      lg: 'size-[36px]',
    };

    const iconSizeClasses = {
      xs: 'w-2 h-2',
      sm: 'w-3 h-3',
      md: 'w-4 h-4',
      lg: 'w-7 h-7',
    };

    return (
      <Button
        ref={ref}
        variant="secondary"
        className={twMerge(
          'rounded-full bg-white flex py-0 shadow-sm px-0 items-center justify-center text-primary ring-2 ring-primary hover:text-primary-dark hover:ring-primary-dark',
          buttonSizeClasses[size],
          className
        )}
        {...props}
      >
        {React.cloneElement(children, {
          className: twMerge(iconSizeClasses[size], children.props.className),
        })}
      </Button>
    );
  }
);
