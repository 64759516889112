import { twMerge } from 'tailwind-merge';

export interface FieldsetProps {
  legend: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

export const Fieldset = ({ legend, children, className }: FieldsetProps) => {
  return (
    <fieldset className={twMerge('flex flex-col gap-4', className)}>
      <legend className="text-primary my-2 font-bold">{legend}</legend>
      {children}
    </fieldset>
  );
};
