import * as React from 'react';
import { useDateFieldState } from '@react-stately/datepicker';
import { useDateField, AriaDateFieldOptions } from '@react-aria/datepicker';
import { useLocale } from '@react-aria/i18n';
import { createCalendar, DateValue } from '@internationalized/date';
import { DateSegment } from './DateSegment';

export type Granularity = 'day' | 'month' | 'year';

export type DateFieldProps<T extends DateValue> = AriaDateFieldOptions<T> & {
  maxGranularity?: 'year' | 'month' | Granularity;
  locale?: string;
  children?: React.ReactNode;
};

export function DateField<T extends DateValue>(props: DateFieldProps<T>) {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = React.useRef<HTMLDivElement>(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className="flex">
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}
