import * as React from 'react';

import { Store } from './Store';

type Setter<T extends object> = (fn: (state: T) => Partial<T>) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shallowCompare = (a: any, b: any) => {
  if (typeof a !== 'object' || typeof b !== 'object') {
    return a === b;
  }

  if (a === null || b === null) {
    return a === b;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  return aKeys.every((key) => a[key] === b[key]);
};

export function create<T extends object>(
  createState: (set: Setter<T>) => T,
  debug = false
) {
  const set = (fn: (state: T) => Partial<T>) => {
    if (debug) {
      console.log('-----------------------------------');
      console.log({
        operation: 'set',
        currentState: store.getState(),
        newState: fn(store.getState()),
      });
      console.log('-----------------------------------');
    }

    const currentState = store.getState();
    const newState = fn(currentState);
    store.setState({ ...currentState, ...newState });
  };

  const initialState = createState(set);
  const store = new Store<T>(initialState);

  function useStore<U>(selector: (state: T) => U) {
    const [state, setState] = React.useState<U>(() =>
      selector(store.getState())
    );

    React.useEffect(() => {
      return store.subscribe((newState) => {
        const newStateValue = selector(newState);

        if (!shallowCompare(state, newStateValue)) {
          setState(newStateValue);
        }
      });
    }, [state, store]);

    return state;
  }

  return useStore;
}
