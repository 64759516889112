import * as React from 'react';

const mediaQuery = {
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
} as const;

export const useWatchMedia = (
  query: keyof typeof mediaQuery,
  defaultState = false
) => {
  const [state, setState] = React.useState(defaultState);

  const handleChange = React.useCallback(() => {
    const isMatching = window.matchMedia(mediaQuery[query]).matches;
    setState(isMatching);
  }, [setState]);

  React.useEffect(() => {
    const observer = new ResizeObserver(handleChange);
    const element = document.body as HTMLElement;

    observer.observe(element);

    return () => observer.disconnect();
  }, [handleChange]);

  return state;
};
