import { MutationHookOptions, useMutation } from '@apollo/client';

import { gql } from '@customer-booking/__generated__';
import {
  SignUpMutation,
  SignUpMutationVariables,
} from '@customer-booking/__generated__/graphql';
import { useTokens } from '../state';

export const SIGN_UP = gql(/* GraphQL */ `
  mutation SignUp($input: SignUpInput!, $companyId: Int) {
    signUp(input: $input, companyId: $companyId) {
      customer {
        id @client
        auth {
          firstName
          lastName
          id
          token
          issuedAt
          expiresAt
        }
      }
      refreshToken
    }
  }
`);

export const useSignUp = (
  options?: MutationHookOptions<SignUpMutation, SignUpMutationVariables>
) => {
  const { setAccessToken, setRefreshToken, clearAll } = useTokens();

  return useMutation<SignUpMutation, SignUpMutationVariables>(SIGN_UP, {
    onCompleted: (data) => {
      setAccessToken(data.signUp.customer.auth.token);
      setRefreshToken(data.signUp.refreshToken);
      options?.onCompleted?.(data);
    },
    onError: (error) => {
      clearAll();
      options?.onError?.(error);
    },
  });
};
