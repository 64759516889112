import * as React from 'react';
import { SVGProps } from 'react';
const SvgAddRideIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 128 118" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#add-ride-icon_svg__a)">
      <path d="M60.922 57.005c-8.38 0-15.177-6.791-15.177-15.172 0-8.38 6.797-15.171 15.177-15.171 8.38 0 15.172 6.791 15.172 15.171 0 8.38-6.792 15.172-15.172 15.172Zm0 59.308 33.885-53.537a39.629 39.629 0 0 0 5.954-20.943c0-22-17.839-39.833-39.839-39.833S21.089 19.833 21.089 41.833c0 7.683 2.177 14.86 5.948 20.943" />
      <path d="M60.922 26.661c-8.38 0-15.177 6.792-15.177 15.172S52.542 57.01 60.922 57.01c8.38 0 15.172-6.796 15.172-15.177 0-8.38-6.792-15.172-15.172-15.172Z" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.667}
        d="M60.922 26.661c-8.38 0-15.177 6.792-15.177 15.172S52.542 57.01 60.922 57.01c8.38 0 15.172-6.797 15.172-15.177 0-8.38-6.792-15.172-15.172-15.172Z"
      />
      <path
        fillRule="evenodd"
        d="M62.049 117.026a1.333 1.333 0 0 1-2.254 0L25.91 63.489l-.007-.01a40.958 40.958 0 0 1-6.148-21.646c0-22.736 18.43-41.166 41.167-41.166 22.736 0 41.172 18.43 41.172 41.166a40.96 40.96 0 0 1-5.277 20.164c-1.154.089-2.288.25-3.397.478l.254-.401.003-.005a38.295 38.295 0 0 0 5.75-20.236c0-21.263-17.241-38.5-38.505-38.5-21.264 0-38.5 17.237-38.5 38.5a38.291 38.291 0 0 0 5.745 20.235l.003.006 32.752 51.745 11.609-18.341a26.922 26.922 0 0 0 1.042 3.34l-11.524 18.208Zm-1.127-58.687c-9.116 0-16.51-7.389-16.51-16.506s7.394-16.505 16.51-16.505c9.117 0 16.505 7.389 16.505 16.505 0 9.117-7.388 16.506-16.505 16.506ZM47.078 41.833c0 7.644 6.2 13.839 13.844 13.839 7.644 0 13.838-6.195 13.838-13.839 0-7.643-6.194-13.838-13.838-13.838-7.644 0-13.844 6.195-13.844 13.838Z"
        clipRule="evenodd"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.667}
        d="M126.088 89.073c0 14.995-12.161 27.156-27.161 27.156-14.995 0-27.156-12.161-27.156-27.156 0-15 12.161-27.156 27.156-27.156 15 0 27.161 12.156 27.161 27.156Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.667}
        d="M100.755 116.312H9.526c-4.14 0-7.526-3.145-7.526-6.994 0-3.844 3.385-6.99 7.526-6.99"
      />
      <path strokeLinecap="round" strokeWidth={2} d="M99 76v26M112 90H86" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.667}
        d="M3.208 88.338h25.86c3.843 0 6.994 3.152 6.994 6.995 0 3.844-3.15 6.995-6.994 6.995H8.995"
      />
    </g>
    <defs>
      <clipPath id="add-ride-icon_svg__a">
        <path d="M0 0h128v118H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAddRideIcon;
