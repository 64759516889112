import { TokenProvider } from '../../auth/services';
import { ApolloLink } from '@apollo/client';

export const createAuthContextLink = ({
  refreshTokenProvider,
  accessTokenProvider,
  cookieTokenProvider,
}: {
  refreshTokenProvider: TokenProvider;
  accessTokenProvider: TokenProvider;
  cookieTokenProvider: TokenProvider;
}) =>
  new ApolloLink((operation, forward) => {
    operation.setContext({
      refreshTokenProvider,
      accessTokenProvider,
      cookieTokenProvider,
    });

    return forward(operation);
  });
