import { ClientError } from '@hermes/icons/colored';
import { Button } from '@hermes/ui';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const AppErrorBoundaryFallback = ({
  resetError,
}: {
  resetError: () => void;
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-2">
      <ClientError width={160} className="m-4" />
      <p className="text-center max-w-[600px] p-4">
        <span className="text-2xl font-bold text-primary mb-2 block">
          <FormattedMessage
            defaultMessage="Oups ! une erreur inattendue est survenue"
            description="Unexpected error title"
          />
        </span>
        <span className="text-base block text-gray-500">
          <FormattedMessage
            defaultMessage="Nous sommes désolés pour la gêne occasionnée. Nos équipes ont été notifiées de l'erreur et travaillent à sa résolution."
            description="Unexpected error description"
          />
        </span>
      </p>
      <Button
        type="button"
        onClick={resetError}
        variant="accent"
        className="mt-2"
      >
        <FormattedMessage
          defaultMessage="Recharger la page"
          description="Reload page button"
        />
      </Button>
    </div>
  );
};
