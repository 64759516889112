import * as React from 'react';
import { createContext } from '@hermes/utils/context';

import { TokenProvider as TokenProviderService } from '../services';

interface TokenProviderContext {
  accessTokenProvider: TokenProviderService;
  refreshTokenProvider: TokenProviderService;
  cookieTokenProvider: TokenProviderService;
}

const [useContext, TokenProvider] = createContext<TokenProviderContext>();

const useTokens = () => {
  const { accessTokenProvider, cookieTokenProvider, refreshTokenProvider } =
    useContext();

  const clearAll = React.useCallback(() => {
    accessTokenProvider.clearToken();
    cookieTokenProvider.clearToken();
    refreshTokenProvider.clearToken();
  }, [accessTokenProvider, cookieTokenProvider, refreshTokenProvider]);

  const setAccessToken = React.useCallback(
    (token: string) => {
      accessTokenProvider.setToken(token);
      cookieTokenProvider.setToken(token);
    },
    [accessTokenProvider, cookieTokenProvider]
  );

  const setRefreshToken = React.useCallback(
    (token: string) => {
      refreshTokenProvider.setToken(token);
    },
    [refreshTokenProvider]
  );

  return {
    accessTokenProvider,
    refreshTokenProvider,
    cookieTokenProvider,
    clearAll,
    setAccessToken,
    setRefreshToken,
  };
};

export { useTokens, TokenProvider };
