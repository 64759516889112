import * as React from 'react';

export interface UseModalProps {
  onOpenChange?: (open: boolean) => void;
  openDefault?: boolean;
}

export const useModal = ({
  onOpenChange: onOpenChangeProp,
  openDefault,
}: UseModalProps = {}) => {
  const [open, setOpen] = React.useState(openDefault ?? false);

  const onOpenChange = (open: boolean) => {
    onOpenChangeProp?.(open);
    setOpen(open);
  };

  return { open, onOpenChange };
};
