import * as React from 'react';
import NextLink from 'next/link';
import { twMerge } from 'tailwind-merge';

const variants = {
  primary: 'text-primary hover:text-primary-dark underline',
  accent: 'text-accent hover:text-accent-dark underline',
  prestige: 'text-accent-prestige hover:text-primary-prestige-dark underline',
};

export interface ExternalLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  external: true;
  className?: string;
  variant?: keyof typeof variants;
}

export interface AppProps extends React.ComponentProps<typeof NextLink> {
  external?: false;
  className?: string;
  variant?: keyof typeof variants;
}

export const Link = (props: ExternalLinkProps | AppProps) => {
  const className = twMerge(
    variants[props.variant ?? 'primary'],
    props.className
  );

  if (props.external) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        className={className}
      />
    );
  }

  return (
    <NextLink
      {...props}
      className={twMerge(variants[props.variant ?? 'primary'], props.className)}
    />
  );
};
